import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// StyleSheet
import "./App.scss";

//Page Layouts
import Header from "./components/layout/Header.jsx";
import Footer from "./components/layout/Footer";

// Pages
import Home from "./pages/home/Home.jsx";
import Company from "./pages/company/Company";
import Offensive from "./pages/offensive/Offensive";
import Penetration from "./pages/penetration/Penetration";
import Managed from "./pages/managed/Managed";
import Assessment from "./pages/assessment/Assessment";
import Innovations from "./pages/innovations/Innovations";
import Error from "./pages/error/Error";

// Add-on's
import Totop from "./components/Additional/scroll-to-top/Totop";
import Form from "./components/Additional/forms/Form";

export default function App() {
  const [toggleState, setToggleState] = useState(1);
  const [toggle, setToggle] = useState(!true);
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  document.onkeydown = (e) => {
    if (e.keyCode === 123) {
      return false;
    }

    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
      return false;
    }

    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
      return false;
    }
    if (e.ctrlKey && e.keyCode === 85) {
      return false;
    }
  };
  document.onselectstart = (e) => {
    return false;
  };

  return (
    <>
      <BrowserRouter>
        <Header setToggleState={setToggleState} setToggle={setToggle} />
        <Form />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route
            path="/offensive-security"
            element={<Offensive toggle={toggle} setToggle={setToggle} />}
          />
          <Route
            path="/penetration-testing"
            element={
              <Penetration
                toggleState={toggleState}
                setToggleState={setToggleState}
              />
            }
          />
          <Route
            path="/managed-security"
            element={
              <Managed
                toggleState={toggleState}
                setToggleState={setToggleState}
              />
            }
          />
          <Route
            path="/security-assessment"
            element={
              <Assessment
                toggleState={toggleState}
                setToggleState={setToggleState}
              />
            }
          />
          <Route path="/innovations" element={<Innovations />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer setToggleState={setToggleState} setToggle={setToggle} />
        <Totop />
      </BrowserRouter>
    </>
  );
}
