import React from "react";

// SVG
import Lottie from "lottie-react";
import LoadingCircle from "../../../svg/loading-circles.json";

// StyleSheet
import "./InnovationsSec.scss";

export default function SectionFive() {
  return (
    <>
      <div className="innovations-sectionFive-block-one">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="comingSoon">
                <Lottie animationData={LoadingCircle} loop={true} />
                <span>
                  <div className="title">
                    <h2>CODINT </h2>
                  </div>
                  <div className="sub-title">
                    <h3>coming soon</h3>
                  </div>
                </span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="comingSoon">
                <Lottie animationData={LoadingCircle} loop={true} />
                <span>
                  <div className="title">
                    <h2>Breach Panda</h2>
                  </div>
                  <div className="sub-title">
                    <h3>coming soon</h3>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
