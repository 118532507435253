import React, { useState } from "react";

// StyleSheet
import "./HomeSec.scss";

// icons
import { BsPlusLg } from "react-icons/bs";

function Tabs() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="container section-two-block">
      <div className="title">
        <h2>Security Preparedness Cycle</h2>
      </div>
      <div className="tab-view">
        <div className="col-lg-5 col-md-12 col-sm-12 bloc-tabs" id="tab-view">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onMouseEnter={() => toggleTab(1)}
          >
            AM I AT RISK?
            <span>
              <BsPlusLg />
            </span>
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onMouseEnter={() => toggleTab(2)}
          >
            AM I PREPARED?
            <span>
              <BsPlusLg />
            </span>
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onMouseEnter={() => toggleTab(3)}
          >
            AM I COMPROMISED?{" "}
            <span>
              <BsPlusLg />
            </span>
          </button>
          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onMouseEnter={() => toggleTab(4)}
          >
            I AM BREACHED!
            <span>
              <BsPlusLg />
            </span>
          </button>
          <button
            className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
            onMouseEnter={() => toggleTab(5)}
          >
            PREPARE FOR FUTURE EVENTS?
            <span>
              <BsPlusLg />
            </span>
          </button>
        </div>
        <div className="col-2"></div>
        <div className="col-lg-5 col-md-12 col-sm-12 content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <ul>
              <li>Red Teaming</li>
              <li>Penetration Testing </li>
              <li>ICS Security Assessment</li>
            </ul>
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <ul>
              <li>Security Program Assessment</li>
              <li>Response Readiness Assessment</li>
              <li>Other strategic services</li>
            </ul>
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <ul>
              <li>Compromise Assessment </li>
            </ul>
          </div>
          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <ul>
              <li>Blue Teaming </li>
            </ul>
          </div>
          <div
            className={
              toggleState === 5 ? "content  active-content" : "content"
            }
          >
            <ul>
              <li>SOC transformation</li>
              <li>Incident Response Retainer</li>
              <li>Education</li>
              <li>Deployment &amp; Integration</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
