import React from "react";

// SVG
import Lottie from "lottie-react";
import BlackBox from "../../../svg/black-box-testing.json";
import GrayBox from "../../../svg/gray-box-testing.json";
import WhiteBox from "../../../svg/white-box-testing.json";

// StyleSheet
import "./PenetrationSec.scss";

// Icons
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

export default function SectionTwo() {
  return (
    <>
      <div className="pen-section-two-block">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Assessment Methods</h2>
          </div>
          <div className="testing">
            <div className="box" data-aos="fade-right">
              <Lottie animationData={BlackBox} loop={true} />
              <h6>
                Black Box <br />
                Testing
              </h6>
            </div>

            <span>
              <BsArrowRight />
            </span>
            <div className="box" data-aos="zoom-in" data-aos-delay="800">
              <Lottie animationData={GrayBox} loop={true} />
              <h6>
                Gray box <br /> Testing
              </h6>
            </div>
            <span>
              <BsArrowLeft />
            </span>
            <div className="box" data-aos="fade-left">
              <Lottie animationData={WhiteBox} loop={true} />
              <h6>
                White Box <br />
                Testing
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
