import React from "react";

// Aos
import AOS from "aos";
import "aos/dist/aos.css";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

// Layouts
import SectionOne from "./layouts/SectionOne";
import SectionTwo from "./layouts/SectionTwo";
import SectionThree from "./layouts/SectionThree";
import SectionFour from "./layouts/SectionFour";
import SectionFivePc from "./layouts/SectionFive";
import Clients from "../company/layouts/Clients";
import Partners from "../company/layouts/Partners";

import Loading from "../../components/Additional/loading/Loading";

export default function Home() {
  AOS.init({
    duration: 1500,
  });

  Loading();
  return (
    <>
      <div className="home-page">
        <div className="Section-one">
          <SectionOne />
        </div>
        <SecDivider />
        <div className="section-two">
          <SectionTwo />
        </div>
        <SecDivider />
        <div className="section-three">
          <SectionThree />
        </div>
        <SecDivider />
        <div className="section-four">
          <SectionFour />
        </div>
        <SecDivider />
        <div className="section-five-pc">
          <SectionFivePc />
        </div>
        <SecDivider />
        <Clients />
        <Partners />
      </div>
    </>
  );
}
