import React from "react";


// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// pages
import Slider from "./layouts/Slider";
import SectionOne from "./layouts/SectionOne";
import SectionTwo from "./layouts/SectionTwo";
import SectionThree from "./layouts/SectionThree";
import SectionFour from "./layouts/SectionFour";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

export default function Penetration({ toggleState, setToggleState }) {
  window.addEventListener("load", function () {
    AOS.init({
      duration: 1500,
      once: false,
      mirror: true,
    });
  });

  return (
    <>
      <div className="slider-section" id="#">
        <Slider />
      </div>

      <div className="section-one">
        <SectionOne
          toggleState={toggleState}
          setToggleState={setToggleState}
         
        />
      </div>
      <SecDivider />
      <div className="section-two">
        <SectionTwo />
      </div>
      <SecDivider />
      <div className="section-three">
        <SectionThree />
      </div>
      <SecDivider />
      <div className="section-four">
        <SectionFour />
      </div>
    </>
  );
}
