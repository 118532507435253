import React, { useEffect } from "react";
import Parallax from "parallax-js";

// SVG
import Lottie from "lottie-react";
import svg from "../../../svg/loading-circles-01.json";

// StyleSheet
import "./InnovationsSec.scss";

// Image src
import img01 from "../../../img/Inovation/img-08.png";

const ParallaxImagesContainer = ({ layers }) => {
  useEffect(() => {
    const parallax_img = document.getElementById("parallax_img");
    new Parallax(parallax_img);
  });

  return (
    <div id="parallax_img">
      <span data-depth="0.3">
        <Lottie animationData={svg} loop={true} />
        <img src={img01} alt="" />
      </span>
      <span data-depth="0.3">
        <Lottie animationData={svg} loop={true} />
        <h4>CODINT</h4>
      </span>
      <span data-depth="0.3">
        <Lottie animationData={svg} loop={true} />
        <h4>Breach Panda</h4>
      </span>
    </div>
  );
};

export default ParallaxImagesContainer;
