import React from "react";

// Image
import Image_01 from "../../../img/home/platforms/01.png";
import Image_02 from "../../../img/home/platforms/02.png";
import Image_03 from "../../../img/home/platforms/03.png";
import Image_04 from "../../../img/home/platforms/04.png";
import Image_05 from "../../../img/home/platforms/05.png";
import Image_06 from "../../../img/home/platforms/06.png";
import Image_07 from "../../../img/home/platforms/07.png";
import Image_08 from "../../../img/home/platforms/08.png";
import Image_09 from "../../../img/home/platforms/09.png";
import Image_10 from "../../../img/home/platforms/10.png";
import Image_11 from "../../../img/home/platforms/11.png";
import Image_12 from "../../../img/home/platforms/12.png";
import Image_13 from "../../../img/home/platforms/13.png";
import Image_14 from "../../../img/home/platforms/14.png";
import Image_15 from "../../../img/home/platforms/15.png";

// StyleSheet
import "./HomeSec.scss";

export default function SectionFour() {
  return (
    <>
      <div className="home-section-four">
        <div className="container-fluid">
          <div className="title">
            <h2>Platforms We Secure</h2>
          </div>
          <div className="images">
            <ul>
              <li>
                <img src={Image_01} alt="" />
              </li>
              <li>
                <img src={Image_02} alt="" />
              </li>
              <li>
                <img src={Image_03} alt="" />
              </li>
              <li>
                <img src={Image_04} alt="" />
              </li>
              <li>
                <img src={Image_05} alt="" />
              </li>
            </ul>
            <ul>
              <li>
                <img src={Image_06} alt="" />
              </li>
              <li>
                <img src={Image_07} alt="" />
              </li>
              <li>
                <img src={Image_08} alt="" />
              </li>
              <li>
                <img src={Image_09} alt="" />
              </li>
              <li>
                <img src={Image_10} alt="" />
              </li>
            </ul>
            <ul>
              <li>
                <img src={Image_11} alt="" />
              </li>
              <li>
                <img src={Image_12} alt="" />
              </li>
              <li>
                <img src={Image_13} alt="" />
              </li>
              <li>
                <img src={Image_14} alt="" />
              </li>
              <li>
                <img src={Image_15} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
