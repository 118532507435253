import React from "react";

// StyleSheet
import "./InnovationsSec.scss";

// SVG
import Lottie from "lottie-react";
import SVG_01 from "../../../svg/bug-discover-01.json";

// Image
import Img_01 from "../../../img/Inovation/img-07.png";

export default function SectionTwo() {
  return (
    <>
      <div className="innovations-sectionTwo-block-two">
        <div className="container">
          <div className="full-img">
            <img src={Img_01} alt="" />
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 center-align">
              <div className="sub-title">
                <h5>Security Empowered through Community </h5>
              </div>
              <div className="content">
                <p>
                  BugDiscover is India’s first crowd-sourced security platform,
                  offering custom solutions to manage bug bounty programmes for
                  business. We are India’s first source to have a responsible
                  disclosure platform – “Zero Discover” – to support both the
                  company and the researcher. We have built a community of
                  security researchers to perform bug-hunting through a managed
                  process that adheres to an organization’s policies and
                  procedures.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 center-align">
              <div className="lottie-img">
                <Lottie animationData={SVG_01} loop={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
