import React, { useState } from "react";

// Stylesheet
import "./CompanySec.scss";

// Image
import Client_01 from "../../../img/company/Client&partners/01.png";
import Client_02 from "../../../img/company/Client&partners/02.png";
import Client_03 from "../../../img/company/Client&partners/03.png";
import Client_04 from "../../../img/company/Client&partners/04.png";
import Client_05 from "../../../img/company/Client&partners/05.png";
import Client_06 from "../../../img/company/Client&partners/06.png";
import Client_07 from "../../../img/company/Client&partners/07.png";
import Client_08 from "../../../img/company/Client&partners/08.png";
import Client_09 from "../../../img/company/Client&partners/09.png";
import Client_10 from "../../../img/company/Client&partners/10.png";
import Client_11 from "../../../img/company/Client&partners/11.png";
import Client_12 from "../../../img/company/Client&partners/12.png";
import Client_13 from "../../../img/company/Client&partners/13.png";
import Client_14 from "../../../img/company/Client&partners/14.png";
import Client_15 from "../../../img/company/Client&partners/15.png";
import Client_16 from "../../../img/company/Client&partners/16.png";
import Client_17 from "../../../img/company/Client&partners/17.png";
import Client_18 from "../../../img/company/Client&partners/18.png";
import Client_19 from "../../../img/company/Client&partners/19.png";
import Client_20 from "../../../img/company/Client&partners/20.png";
import Client_21 from "../../../img/company/Client&partners/21.png";

// Swiper
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Icon
import { FiPlus } from "react-icons/fi";

export default function Client_s() {
  const [toggle, setToggle] = useState(!true);

  const popUp = () => {
    setToggle(true);
  };
  const close = () => {
    setToggle(!true);
  };
  return (
    <>
      <div className="clients-section-block" data-aos="fade-in">
        <div className="container">
          <div className="title">
            <h2>
              Client &amp; Partners
              <span onClick={popUp}>
                <FiPlus />
              </span>
            </h2>

            <div className={toggle ? "active pop-up" : "pop-up"}>
              <div className="close-btn" onClick={close}>
                X
              </div>
              <img src={Client_01} alt="" />
              <img src={Client_02} alt="" />
              <img src={Client_03} alt="" />
              <img src={Client_04} alt="" />
              <img src={Client_05} alt="" />
              <img src={Client_06} alt="" />
              <img src={Client_07} alt="" />
              <img src={Client_08} alt="" />
              <img src={Client_09} alt="" />
              <img src={Client_10} alt="" />
              <img src={Client_11} alt="" />
              <img src={Client_12} alt="" />
              <img src={Client_13} alt="" />
              <img src={Client_14} alt="" />
              <img src={Client_15} alt="" />
              <img src={Client_16} alt="" />
              <img src={Client_17} alt="" />
              <img src={Client_18} alt="" />
              <img src={Client_19} alt="" />
              <img src={Client_20} alt="" />
              <img src={Client_21} alt="" />
            </div>
          </div>
          <div className="col-12">
            <Swiper
              dir="rtl"
              slidesPerView={6}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                500: {
                  slidesPerView: 6,
                },
              }}
              speed={1000}
              modules={[Autoplay]}
              className="client-slider"
            >
              <SwiperSlide>
                <img src={Client_01} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_02} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_03} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_04} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_05} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_06} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_07} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_08} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_09} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_10} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_11} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_12} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_13} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_14} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_15} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_16} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_17} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_18} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_19} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_20} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Client_21} alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
