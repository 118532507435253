import React from "react";

import Infograp from "../../../img/home/img-04.png";

export default function SectionThree() {
  return (
    <>
      <div className="container section-three-block">
        <div className="title">
          <h2>Our Solution Matrix</h2>
        </div>
        <div className="full-img" data-aos="zoom-in">
          <img src={Infograp} alt=""/>
        </div>
      </div>
    </>
  );
}
