import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

// Stylesheet
import "../layouts/OffensiveSec.scss";

// Image
import Img_01 from "../../../img/offensive-security/image-05.png";
import Img_02 from "../../../img/offensive-security/image-02.png";
import Img_03 from "../../../img/offensive-security/image-03.png";
import Img_04 from "../../../img/offensive-security/image-04.png";

import Icon01_01 from "../../../img/offensive-security/Goal Oriented.png";
import Icon01_02 from "../../../img/offensive-security/Adversary Techniques.png";
import Icon01_03 from "../../../img/offensive-security/Covert Operations.png";
import Icon01_04 from "../../../img/offensive-security/Fact Based Analysis.png";
import Icon01_05 from "../../../img/offensive-security/Experience Adversaries.png";
import Icon01_06 from "../../../img/offensive-security/Mature Blue Team.png";

// icons
import { FiCheckCircle } from "react-icons/fi";

// video
import Bg_01 from "../../../video/offensive-secutiy-bg-01.mp4";

export default function SectionTwo({ toggle, setToggle }) {
  const swapTab = (index) => {
    setToggle(index);
  };

  return (
    <>
      <div className="offensive-section-two">
        <div className="container-fluid tab-button">
          <video muted loop autoPlay id="content-visible">
            <source src={Bg_01} />
          </video>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 box" id="Services">
              <div className={toggle === 1 ? "active tab-link" : "tab-link"}>
                <h2>Cyber Red Team</h2>
                <p>
                  Our Cyber Red Team Operations are designed to simulate a
                  realistic attack to identify weaknesses in organizations
                </p>
                <HashLink
                  exact
                  to="#content-visible"
                  onClick={() => swapTab(1)}
                >
                  Know More
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 box">
              <div className={toggle === 2 ? "active tab-link" : "tab-link"}>
                <h2>Cyber Purple Team</h2>
                <p>
                  Our Purple teaming exercise helps security teams to improve
                  the effectiveness of vulnerability detection
                </p>
                <HashLink
                  exact
                  to="#content-visible"
                  onClick={() => swapTab(2)}
                >
                  Know More
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 box">
              <div className={toggle === 3 ? "active tab-link" : "tab-link"}>
                <h2>R&amp;D</h2>
                <p>
                  Our cyber Red Team have a dedicated exploit research and
                  development setup to build different arsenals
                </p>
                <HashLink
                  exact
                  to="#content-visible"
                  onClick={() => swapTab(3)}
                >
                  Know More
                </HashLink>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid  p-0">
          <div className={toggle === 1 ? "active tab-content" : "tab-content"}>
            <div className="container">
              <div className="row block">
                <div className="col-12">
                  <div className="title">
                    <h2>Cyber Red Team</h2>
                  </div>
                  <div className="content">
                    <p>
                      Our Cyber Red Team Operations are multi-blended,
                      adversarial-based attack simulation against people,
                      software, hardware, and facilities performed
                      simultaneously for the protection of organization data.
                      The operations are designed to simulate a realistic attack
                      to identify weaknesses in organizations existing security
                      controls and to assess the efficacy of information
                      security detection, prevention, and response capabilities.
                      Our adversaries are built by unique research and Intel
                      techniques to create a similar impact of a targeted
                      attack. Our Operations use real-world adversarial TTPs to
                      compromise an organizations perimeter and simulate data
                      theft to accomplish the objective through any means
                      necessary, without disrupting normal business operations.
                      Our Cyber Red team Operations will support in decision
                      making in almost any context like security, short- or
                      long-term strategy, Finance, engineering design, and even
                      organizational decisions.
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="full-img">
                    <img src={Img_02} alt="" />
                  </div>
                </div>
                <div className="title">
                  <h2>Red Team Operations</h2>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="full-img">
                      <img src={Img_03} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 center-align flex-col">
                    <div className="content">
                      <div className="col-12 highlighted-words">
                        <q>
                          Our Cyber Red Team Operation are designed to defend
                          against target threats scenarios and helps
                          organizations to constantly adapt and innovate.
                        </q>
                      </div>

                      <p>
                        Our Cyber Red Team Ops are threat intelligence driven
                        which involves a combination of OSINT, HUMINT, TECHINT
                        and FINIT. The goal is to build different TTP’s to
                        penetrate and gain access to different domains of an
                        organization.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="title">
                  <h2>Our Core Activities</h2>
                </div>
                <div className="long-list-out">
                  <ul>
                    <li>
                      Red Team <br /> Engagements
                    </li>
                    <li>Reconnaissance</li>
                    <li>Enumeration</li>
                    <li>
                      Social Engineering <br /> Attacks
                    </li>
                    <li>Password Cracking</li>
                    <li>
                      Bypassing <br /> Windows User <br /> Account Control
                    </li>
                  </ul>
                  <ul>
                    <li>
                      PowerShell <br />
                      Exploitation
                    </li>
                    <li>Lateral Movement</li>
                    <li>
                      Network Domination <br /> &amp; Persistence
                    </li>
                    <li>Network Infrastructure Exploitation</li>
                    <li>Testing/Configuration</li>
                    <li>DNS Foot Printing</li>
                  </ul>
                  <ul>
                    <li>Configuration</li>
                    <li>Evasion &amp; Obfuscation Techniques</li>
                    <li>Data Exfiltration</li>
                    <li>Attacking Linux/Unix Environments</li>
                    <li>Privilege Escalation</li>
                    <li>Virtualization Attacks</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={toggle === 2 ? "active tab-content" : "tab-content"}>
            <div className="container">
              <div className="row">
                <div className="title">
                  <h2 >Cyber Purple Team</h2>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="content">
                    <p >
                      Our Cyber Red Team Operations are also designed to defend
                      against target threats scenarios and helps organizations
                      to constantly adapt and innovate. Our team and
                      organizations Cyber BlueTeam will work together on an
                      ongoing basis to maximize their individual and collective
                      effectiveness. Our Purple teaming exercise helps security
                      teams to improve the effectiveness of vulnerability
                      detection, threat hunting and network monitoring by
                      accurately simulating common threat scenarios and
                      facilitating the creation of new TTP’s to prevent and
                      detect new types of threats.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 icons-section">
                  <div className="icons-box">
                    <div className="box">
                      <div className="inn-box">
                        <span>
                          <img src={Icon01_01} alt="" />
                        </span>
                        <p>Goal Oriented</p>
                      </div>
                      <div className="inn-box">
                        <span>
                          <img src={Icon01_02} alt="" />
                        </span>
                        <p>Adversary Techniques</p>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inn-box">
                        <span>
                          <img src={Icon01_03} alt="" />
                        </span>
                        <p>Covert Operations</p>
                      </div>
                      <div className="inn-box">
                        <span>
                          <img src={Icon01_04} alt="" />
                        </span>
                        <p>Fact Based Analysis</p>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inn-box">
                        <span>
                          <img src={Icon01_05} alt="" />
                        </span>
                        <p>Experience Adversaries</p>
                      </div>
                      <div className="inn-box">
                        <span>
                          <img src={Icon01_06} alt="" />
                        </span>
                        <p>Mature Blue Team</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="title">
                  <h2>Purple Team Operations</h2>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 list center-align">
                  <ul>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>
                        Validate protections and monitoring around high-value
                        systems
                      </span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>
                        Implement the Team's operational methodology and TTP.
                      </span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>
                        Identification of possible weak points on logical
                        security
                      </span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span> Competitive Intelligence</span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>Exploit Research and Development</span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>
                        Security testing of Firewalls, ACL other security
                        devices
                      </span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>Identification of Rouge Devices</span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>
                        Threat modelling, mitigation strategies and lessons
                        learned debrief
                      </span>
                    </li>
                    <li>
                      <span>
                        <FiCheckCircle />
                      </span>
                      <span>
                        Determining appropriate counter measures to thwart
                        malicious hacking
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="full-img">
                    <img src={Img_04} alt="" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="sub-title">
                  <h5>Bridging Blue Team and Red Team</h5>
                </div>

                <div className="content">
                  <p>
                    The Objective is to carry out Cyber Red Team Operation by
                    proactively simulating real attacks and attempt to penetrate
                    security controls undetected. The operation defined is more
                    of objective and scenario driven process.
                  </p>
                </div>

                <div className="cycle-process">
                  <div className="box">
                    <div className="overlay">
                      <div className="inner-box">
                        <h6>
                          Office of the <br /> CISO/CSO/CIO
                        </h6>
                      </div>
                      <hr />
                      <div className="inner-box">
                        <h6>Security Operations</h6>
                      </div>
                      <hr />
                      <div className="inner-box">
                        <h6>Incident Response and Support</h6>
                      </div>
                    </div>
                    <div className="name-tag">
                      <h6>Blue Teaming</h6>
                    </div>
                  </div>
                  <hr className="outer" />
                  <div className="box">
                    <div className="overlay">
                      <h6>Purple Teaming</h6>
                    </div>
                    <div className="name-tag"></div>
                  </div>
                  <hr className="outer" />
                  <div className="box">
                    <div className="overlay">
                      <div className="inner-box">
                        <h6>Black Box Penetration Testing</h6>
                      </div>
                      <hr />
                      <div className="inner-box">
                        <h6>Social Engineering</h6>
                      </div>
                      <hr />
                      <div className="inner-box">
                        <h6>Exploitation</h6>
                      </div>
                      <hr />
                      <div className="inner-box">
                        <h6>Offensive Security Techniques</h6>
                      </div>
                    </div>
                    <div className="name-tag">
                      <h6>Red Teaming</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={toggle === 3 ? "active tab-content" : "tab-content"}>
            <div className="container r-d">
              <div className="row block">
                <div className="title">
                  <h2>R&amp;D</h2>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 center-align">
                  <div className="content ">
                    <p>
                      Our cyber Red Team have a dedicated exploit research and
                      development setup to build different arsenals for red
                      teaming activities. The primary focus of the R&amp;D will
                      be on Pre Exploitation Vulnerabilities to perform remote
                      code execution and other exploitation techniques. Our
                      R&amp;D also focuses on identifying post exploitation
                      vulnerabilities to maintain access or to perform further
                      privilege escalation/lateral movement. Above we have a
                      dedicated threat Intel sources to collect exploit samples
                      through reverse engineering of malware which adds more
                      value to understand the trends of current vulnerabilities
                      and threats.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="full-img">
                    <img src={Img_01} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
