import React from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

// Layouts
import Slider from "./layouts/Slider";
import SectionTwo from "./layouts/SectionTwo";
import SectionThree from "./layouts/SectionThree";
import SectionFour from "./layouts/SectionFour";

window.addEventListener("load", function () {
  AOS.init({
    duration: 1500,

    mirror: true,
  });
});

export default function Assessment({ toggleState, setToggleState }) {
  return (
    <>
      <div className="slider-section" id="#">
        <Slider />
      </div>
      <SecDivider />

      <div className="section-two">
        <SectionTwo />
      </div>
      <SecDivider />
      <div className="section-three">
        <SectionThree
          toggleState={toggleState}
          setToggleState={setToggleState}
        />
      </div>
      <SecDivider />
      <div className="section-four" id="what-we-do">
        <SectionFour
          toggleState={toggleState}
          setToggleState={setToggleState}
        />
      </div>
    </>
  );
}
