import React, { useState } from "react";

// Stylesheet
import "./CompanySec.scss";

// svg
import Lottie from "lottie-react";
import svg from "../../../svg/lock&open.json";

// Add-on's
import SecDivider from "../../../components/Additional/divider/SecDivider";

// Image src
import Icon01 from "../../../img/company/about-icon-01.png";
import Icon02 from "../../../img/company/about-icon-02.png";
import Icon03 from "../../../img/company/about-icon-03.png";
import Icon04 from "../../../img/company/about-icon-04.png";
import Icon05 from "../../../img/company/about-icon-05.png";
import Icon06 from "../../../img/company/about-icon-06.png";
import Icon07 from "../../../img/company/about-icon-07.png";
import Icon08 from "../../../img/company/about-icon-08.png";

// Swiper
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function About() {
  // const [click, setClick] = useState(1);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
 
  return (
    <>
      <div className="container-fluid about-section-block-one">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 block-two-inn-one"  data-aos="zoom-in"></div>
          <div className="col-lg-6 col-md-12 col-sm-12 block-two-inn-two center-align">
            <div className="container">
              <div className="according-section">
                <div className="sub-title"  data-aos="fade">
                  <h6>// About</h6>
                </div>
                <div className="title" data-aos="fade">
                  <h2>Modernizing Information Security</h2>
                </div>

                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <div class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h5>Out of the Box</h5>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          RedTeam Factory Solutions Pvt Ltd stands out of the
                          box from the legacy security practices, our practices
                          are implemented precisely by segmenting sector wise
                          thus providing the right solutions to the right
                          segment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h5>Meet Security Needs</h5>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Our solutions are designed to follow a unique method
                          in planning and modeling the threats by understanding
                          our client’s business modus operandi to meets their
                          security needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <h5>Startups till Enterprises</h5>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          The wide range of solutions we provide help startups
                          till Enterprises to protect their business, data,
                          brand, trade secrets and their customers from the
                          growing threats that are exploited everyday across the
                          globe.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecDivider />
      <div className="about-section-block-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12"  data-aos="fade-in">
              <div className="block-three-inn-two">
                <div className="tab-view">
                  <div className="col-5 bloc-tabs">
                    <button
                      className={
                        toggleState === 1 ? "tabs active-tabs" : "tabs"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      What We DO?
                    </button>
                    <button
                      className={
                        toggleState === 2 ? "tabs active-tabs" : "tabs"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      Who we are?
                    </button>
                    <button
                      className={
                        toggleState === 3 ? "tabs active-tabs" : "tabs"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      Goals
                    </button>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-5 content-tabs">
                    <div
                      className={
                        toggleState === 1
                          ? "content  active-content"
                          : "content"
                      }
                    >
                      <p>
                        RedTeam Factory offers extensive security solutions,
                        where we build your defenses using offensive security
                        solutions. Our solutions include multi blended
                        implementation techniques that involves security at each
                        layer of your organization thus providing 360&deg;
                        security model. Our specializations areas include –
                        Offensive Security, Penetration Testing, Threat Intel,
                        SOC &amp; Compliance Implementations.
                      </p>
                    </div>

                    <div
                      className={
                        toggleState === 2
                          ? "content  active-content"
                          : "content"
                      }
                    >
                      <p>
                        We are a group of Ethical Hackers, InfoSec
                        Professionals, IT Engineers, and Researchers who
                        research on defensive and offensive security. We exist
                        in this space of InfoSec for the past 14 years, since
                        then we have supported several Financial Institutions,
                        Corporates, Private and Public Sectors. We have detected
                        and reported vulnerabilities to several organizations
                        under responsible disclosure policy.
                      </p>
                    </div>

                    <div
                      className={
                        toggleState === 3
                          ? "content  active-content"
                          : "content"
                      }
                    >
                      <p>
                        RedTeam Factory believes security today is well
                        established but not delivered in a precise model, and
                        this is the fundamental setback many organizations face.
                        Our goal is to decipher this setback by implementing
                        Strategic alignment with business strategy to support
                        organizational objectives towards security. Our focus is
                        to provide secure environment where risk is carefully
                        managed, and protection of assets is both comprehensive
                        and pervasive which also enhances optimized security
                        investment for value delivery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="block-three-inn-one">
                <Lottie animationData={svg} loop={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecDivider />
      <div className=" about-section-block-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 inn-one">
              <h2>Industries We Secure </h2>
              <p>
                All our services are bundled with experience and expertise to
                integrate all relevant assurance factors to ensure that
                processes operate as intended end-to-end, which provides
                organization a real seal of security from the real time
                malicious hackers.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 inn-two"  data-aos="fade-in">
              <Swiper
                slidesPerView={2}
                slidesPerGroup={2}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                speed={1000}
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay]}
                className="About-sec-four-slider"
              >
                <SwiperSlide>
                  <div className="card">
                    <div className="one">
                      <img src={Icon01} alt="" />
                      <h5>IT/ITES</h5>
                    </div>
                    <div className="two">
                      <img src={Icon05} alt="" />
                      <h5>IOT &amp; IIOT</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <div className="one">
                      <img src={Icon02} alt="" />
                      <h5>Financial Institutions</h5>
                    </div>
                    <div className="two">
                      <img src={Icon06} alt="" />
                      <h5>Educational Institutions</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <div className="one">
                      <img src={Icon03} alt="" />
                      <h5>Health care Institutions</h5>
                    </div>
                    <div className="two">
                      <img src={Icon07} alt="" />
                      <h5>Corporates, SMEs &amp; Start-ups</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <div className="one">
                      <img src={Icon04} alt="" />
                      <h5>Automotive Industries</h5>
                    </div>
                    <div className="two">
                      <img src={Icon08} alt="" />
                      <h5>Research and Analytics</h5>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
