import React from "react";

// Image
import Slider01 from "../../../img/company/award-01.png";
import Slider02 from "../../../img/company/award-02.png";

// StyleSheet
import "./CompanySec.scss";

// Swiper
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Awards() {
  return (
    <>
      <div className="award-section-block">
        <div className="container">
          <div className="title">
            <h2>Awards &amp; Recognition </h2>
          </div>
          <div className="col-12">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              // centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={1000}
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              className="awardsSwiper"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 60,
                },
                500: {
                  slidesPerView: 2,
                  spaceBetween: 60,
                },
                800: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              data-aos="fade-down" >
              <SwiperSlide className="swiperCard mt-5" >
                <div className="slide-img">
                  <img src={Slider01} alt="" />
                </div>

                <p>
                  BEST Crowd Sourced Cyber Security Company - South Asia (Cyber
                  Security 2022 Awards-W&amp;F International, UK)
                </p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard">
                <div className="slide-img">
                  <img src={Slider02} alt="" />
                </div>

                <p>
                  Official BugBounty Partner of National Cyber Defence Research
                  Center(NCDRC)
                </p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard mt-5">
                <div className="slide-img">
                  <img src={Slider01} alt="" />
                </div>

                <p>
                  BEST Cyber Security Solution of the Year - IT/ITES (EC-Council
                  CISO Mag Awards, March 2019, Mumbai)
                </p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard">
                <div className="slide-img">
                  <img src={Slider02} alt="" />
                </div>
                <p>
                  Nominee of Trescon Popular Choice Award Category ‘Big 50
                  Innovators’. (8th Edition #BigCIOShow, May 2019)
                </p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard mt-5">
                <div className="slide-img">
                  <img src={Slider02} alt="" />
                </div>

                <p>
                  Conducted India's Biggest Live bug hunting event at c0c0n 2019
                  @cochin
                </p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard">
                <div className="slide-img">
                  <img src={Slider01} alt="" />
                </div>

                <p>
                  Top Cyber Security Start-Up Award (CISO Platform 100, Feb
                  2019, Bangalore)
                </p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard mt-5">
                <div className="slide-img">
                  <img src={Slider02} alt="" />
                </div>

                <p>Trained 2500+ Corporate employees</p>
              </SwiperSlide>
              <SwiperSlide className="swiperCard">
                <div className="slide-img">
                  <img src={Slider02} alt="" />
                </div>
                <p>Invited Lectures at various International Conferences</p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
