import React from "react";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

// Pages
import SectionOne from "./layouts/SectionOne";
import SectionTwo from "./layouts/SectionTwo";
import SectionThree from "./layouts/SectionThree";
import SectionFour from "./layouts/SectionFour";
import SectionFive from "./layouts/SectionFive";

export default function Innovations() {
  return (
    <>
      <div className="section-one">
        <SectionOne />
      </div>
      <SecDivider />
      <div className="section-two" id="inno-sec-two">
        <SectionTwo />
      </div>
      <SecDivider />
      <div className="section-three">
        <SectionThree />
      </div>
      <SecDivider />
      <div className="section-four">
        <SectionFour />
      </div>
      <SecDivider />
      <div className="section-five" id="inno-sec-five">
        <SectionFive />
      </div>
    </>
  );
}
