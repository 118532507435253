import React from "react";
import { HashLink } from "react-router-hash-link";

// Stylesheet
import "./AssessmentSec.scss";

// SVG
import Lottie from "lottie-react";
import svg from "../../../svg/Cube.json";

//Image src

import Icon01 from "../../../img/security-assessment/Risk-Assessment-icon.png";
import Icon02 from "../../../img/security-assessment/Compromise-Assessment-icon.png";
import Icon03 from "../../../img/security-assessment/Security-Configuration-Assessment--icon.png";
import Icon04 from "../../../img/security-assessment/Vulnerability-Assessment-icon.png";
import Icon05 from "../../../img/security-assessment/Social-Engineering-Assessment-icon.png";
import Icon06 from "../../../img/security-assessment/Compliance-based-Assessment-icon.png";
import Icon07 from "../../../img/security-assessment/OSINT-icon.png";

export default function SectionThree({ setToggleState }) {
  return (
    <>
      <div className="sectionThree-block-one">
        <div className="container">
          <div className="sub-title">
            <h6> // SOLUTIONS AT A GLANCE</h6>
          </div>
          <div className="title">
            <h2>Security Assessment</h2>
          </div>

          <div className="pc-view">
            <PcView setToggleState={setToggleState} />
          </div>
          <div className="tab-view">
            <TabView />
          </div>
        </div>
      </div>
    </>
  );
}

function PcView({ setToggleState }) {
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <div className="row">
        <div className="col-4 left-content">
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(1)}>
                  Risk Assessment
                </HashLink>
              </h6>
              <span>
                <img src={Icon01} alt="" />
              </span>
            </div>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(2)}>
                  Compromise Assessment
                </HashLink>
              </h6>
              <span>
                <img src={Icon02} alt="" />
              </span>
            </div>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(3)}>
                  Security Configuration Assessment
                </HashLink>
              </h6>
              <span>
                <img src={Icon03} alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="full-img">
            <Lottie animationData={svg} loop={true} />
          </div>
        </div>
        <div className="col-4 right-content">
          <div className="box">
            <div className="box-title" data-aos="fade-left">
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(4)}>
                  Vulnerability Assessment
                </HashLink>
              </h6>
              <span>
                <img src={Icon04} alt="" />
              </span>
            </div>
          </div>
          <div className="box" data-aos="fade-left">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(5)}>
                  Social Engineering Assessment
                </HashLink>
              </h6>
              <span>
                <img src={Icon05} alt="" />
              </span>
            </div>
          </div>
          <div className="box" data-aos="fade-left">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(6)}>
                  Compliance based Assessment
                </HashLink>
              </h6>
              <span>
                <img src={Icon06} alt="" />
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 bottom-content">
          <div className="box" data-aos="fade">
            <div className="box-title">
              <span>
                <img src={Icon07} alt="" />
              </span>
              <h6>
                <HashLink exact to="#what-we-do" onClick={() => toggleTab(7)}>
                  OSINT
                </HashLink>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TabView() {
  return (
    <>
      <div className="row">
        {/* <div className="col-md-12 col-sm-12 lottie-section">
          <div className="full-img">
            <Lottie animationData={svg} loop={true} />
          </div>
        </div> */}
        <div className="col-md-12 col-sm-12 icon-section">
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  Risk Assessment
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon01} alt="" />
            </span>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  Compromise Assessment
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon02} alt="" />
            </span>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  Security Configuration Assessment
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon03} alt="" />
            </span>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  Vulnerability Assessment
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon04} alt="" />
            </span>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  Social Engineering Assessment
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon05} alt="" />
            </span>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  Compliance based Assessment
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon06} alt="" />
            </span>
          </div>
          <div className="box" data-aos="fade-right">
            <div className="box-title">
              <h6>
                <HashLink exact to="#what-we-do">
                  OSINT
                </HashLink>
              </h6>
            </div>
            <span>
              <img src={Icon07} alt="" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
