import React from "react";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

// Layouts
import Slider from "./layouts/Slider";
import About from "./layouts/About";
import Awards from "./layouts/Awards";
import Clients from "./layouts/Clients";
import Partners from "./layouts/Partners";

export default function Company() {
  return (
    <>
      <div className="slider-section" id="#">
        <Slider />
      </div>
      <SecDivider />
      <div className="about-section" id="about">
        <About />
      </div>
      <SecDivider />
      <div className="awards-section" id="awards">
        <Awards />
      </div>
      <div className="clients-section" id="clients">
        <Clients />
      </div>
      <div className="partners-section" id="partners">
        <Partners />
      </div>
    </>
  );
}
