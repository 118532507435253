import React from "react";

// layout
import Banner from "./layouts/Banner";
import SectionOne from "./layouts/SectionOne";
import SectionTwo from "./layouts/SectionTwo";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

export default function Offensive({ toggle, setToggle }) {
  return (
    <>
      <div className="banner" id="#">
        <Banner />
      </div>
      <SecDivider />
      <div className="section-one">
        <SectionOne />
      </div>
      <SecDivider />
      <div className="section-two" id="#Services">
        <SectionTwo toggle={toggle} setToggle={setToggle} />
      </div>
    </>
  );
}
