import React from "react";
import { HashLink } from "react-router-hash-link";
import { FaMouse, FaArrowCircleRight } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";

// Image src
import Image_01 from "../../../img/home/img-02.png";
import DevIcon from "../../../img/home/coding-icon.png";
import Slider01 from "../../../img/home/award-01.png";
import Slider02 from "../../../img/home/award-02.png";

// Video Src
import BgVideo from "../../../video/home-bg-01.mp4";

// Swiper
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SectionOne = () => {
  return (
    <>
      <div className="section-one-block">
        <div className="sticky-bg-image">
          <video autoPlay loop muted>
            <source src={BgVideo} />
          </video>
        </div>
        <div className="container-fluid">
          <div className="row welcome-inn-sec">
            <div
              className="col-xl-5 col-lg-5 col-md-12 col-sm-12 sticky-section"
              data-aos="fade-down"
            >
              <h2>
                RedTeam Factory offers extensive security solutions, where we
                build your defenses using offensive security solutions.
              </h2>
            </div>
            <div className="col-1"></div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 scroll-section">
              <div>
                <div className="mouse-icon">
                  <FaMouse />
                </div>
                <div className="row scroll-section-inn-one">
                  <div className="col-12" data-aos="fade-in">
                    <h5>
                      The earlier you set security controls and restraints, the
                      better off you will be at preventing a data breach
                    </h5>
                  </div>
                  <div className="col-12" data-aos="fade-in">
                    <p>
                      Cyber threats continue to multiply and represent an
                      existential threat to businesses of all sizes. The advent
                      of today is always-on, universally interconnected digital
                      world opens up a whole new universe of opportunities,
                      threats and vulnerabilities. This has elevated cyber
                      security to a high-priority, broad-level issue.
                    </p>
                  </div>
                  <div className="col-12" data-aos="fade-in">
                    <HashLink className="download-btn" exact to="/company#">
                      <span>Know More</span>
                      <span>
                        <FaArrowCircleRight />
                      </span>
                    </HashLink>
                  </div>
                </div>
                <div className="row scroll-section-inn-two">
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 scroll-section-inn-two-banner"
                    data-aos="fade-in"
                  >
                    <p>
                      Even though we have the best technologies, the attack
                      surface of organizations is rapidly expanding.
                    </p>
                    <p>
                      Our Solutions are modelled to address and analyse
                      different verticals of threats that an organization would
                      face. we build the defenses at each layer of the
                      organisation to plan and mitigate an attack even before it
                      affect the eco system.
                    </p>
                    <HashLink exact to="/managed-security#">
                      <span>Find Your Solution</span>
                      <span>
                        <FaArrowCircleRight />
                      </span>
                    </HashLink>
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-sm-12 inn-two"
                    data-aos="fade-in"
                  >
                    <img src={Image_01} alt="" />
                  </div>
                </div>
                <div className="row scroll-section-inn-three">
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 inn-one"
                    data-aos="fade-in"
                  >
                    <img src={DevIcon} alt="" />

                    <h4>Vulnerability-free applications are still a myth!</h4>
                    <p>
                      Despite the fact that organizations have large security
                      budgets, there are still breaches and threats.
                    </p>
                    <HashLink exact to="/penetration-testing#">
                      <span>Find Your Solution</span>
                      <span>
                        <FaArrowCircleRight />
                      </span>
                    </HashLink>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 inn-two"
                    data-aos="fade-in"
                  >
                    <ul>
                      <li>
                        <i>
                          <GoPrimitiveDot />
                        </i>
                        Organization IT surface always have a vast degree of
                        Vulnerability
                      </li>
                      <li>
                        <i>
                          <GoPrimitiveDot />
                        </i>
                        Organizations are still not effective in preventing
                        determined attacks
                      </li>
                      <li>
                        <i>
                          <GoPrimitiveDot />
                        </i>
                        Professional attackers remain hidden for several
                        months/years before detection
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row scroll-section-inn-four">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Our awards and recognitions</h3>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p>
                      Trusted by the world's best organizations for years and
                      delivering Security to hundreds of Organizations.
                    </p>
                  </div>
                  <div className="col-12" data-aos="fade-in">
                    <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={30}
                      loop={true}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                      speed={500}
                      pagination={{ clickable: true }}
                      modules={[Pagination, Autoplay]}
                      className="homeSwiper"
                    >
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider01} alt="" />
                        </div>

                        <p>
                          BEST Crowd Sourced Cyber Security Company - South Asia
                          (Cyber Security 2022 Awards-W&amp;F International, UK)
                        </p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider02} alt="" />
                        </div>

                        <p>
                          Official BugBounty Partner of National Cyber Defence
                          Research Center(NCDRC)
                        </p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider01} alt="" />
                        </div>

                        <p>
                          BEST Cyber Security Solution of the Year - IT/ITES
                          (EC-Council CISO Mag Awards, March 2019, Mumbai)
                        </p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider02} alt="" />
                        </div>
                        <p>
                          Nominee of Trescon Popular Choice Award Category ‘Big
                          50 Innovators’. (8th Edition #BigCIOShow, May 2019)
                        </p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider02} alt="" />
                        </div>

                        <p>
                          Conducted India's Biggest Live bug hunting event at
                          c0c0n 2019 @cochin
                        </p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider01} alt="" />
                        </div>

                        <p>
                          Top Cyber Security Start-Up Award (CISO Platform 100,
                          Feb 2019, Bangalore)
                        </p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider02} alt="" />
                        </div>

                        <p>Trained 2500+ Corporate employees</p>
                      </SwiperSlide>
                      <SwiperSlide className="swiperCard">
                        <div className="slide-img">
                          <img src={Slider02} alt="" />
                        </div>
                        <p>
                          Invited Lectures at various International Conferences
                        </p>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
