import React from "react";

// StyleSheet
import "./InnovationsSec.scss";

// SVG
import Lottie from "lottie-react";
import SVG_01 from "../../../svg/bug-discover-02.json";

// Icons
import { BiLockOpenAlt, BiLockAlt, BiTargetLock } from "react-icons/bi";
import { MdOutlineSecurity } from "react-icons/md";

export default function SectionThree() {
  return (
    <>
      <div className="innovations-sectionThree-block-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12 center-align">
              <div className="lottie-img">
                <Lottie animationData={SVG_01} loop={true} />
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12 center-align">
              <div className="sub-title">
                <h5>Crowd Sourced Security Solutions</h5>
              </div>
              <div className="content">
                <p>
                  We ensure that our clients benefit from the capacity of
                  diverse research that augments current security practices and
                  protocols, as well as lower OPEX and increased operational
                  efficiency. We ensure that our platform provides a clear ROI
                  by accepting valid submissions and providing immediate triage
                  results. We guarantee that our team will be constantly guiding
                  and providing positive reinforcement.
                </p>
              </div>
            </div>
          </div>
          <div className="row icons-block">
            <div className="title">
              <h2>Our Managed Services</h2>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="content">
                <p>
                  All of our methods &amp; services are bundled with experience
                  and expertise, with the primary goal of delivering a complete
                  solution without sacrificing any information.
                </p>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="list">
                <ul>
                  <li>Platform As a Service</li>
                  <li>Program Management As a Service</li>
                  <li>Rewarding System As a Service</li>
                  <li>Remediation As a Service</li>
                </ul>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="sub-title">
                <h5>Our Innovative Discovery Methods</h5>
              </div>
              <div className="icons-section">
                <div className="box">
                  <span>
                    <BiLockOpenAlt />
                  </span>
                  <h4>
                    <span className="blue">Open </span>
                    <span className="red">Discover</span>
                  </h4>
                </div>
                <div className="box">
                  <span>
                    <BiLockAlt />
                  </span>
                  <h4>
                    <span className="blue">Private </span>
                    <span className="red">Discover</span>
                  </h4>
                </div>

                <div className="box">
                  <span>
                    <MdOutlineSecurity />
                  </span>
                  <h4>
                    <span className="blue">On-Prem </span>
                    <span className="red">Discover</span>
                  </h4>
                </div>
                <div className="box">
                  <span>
                    <BiTargetLock />
                  </span>
                  <h4>
                    <span className="blue">Zero </span>
                    <span className="red">Discover</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
