import React, { useState } from "react";
import { BiMessageDetail } from "react-icons/bi";

// StyleSheet
import "./Forms.scss";

export default function StickyForm() {
  const [togglebtn, setTogglebtn] = useState(!true);
  const showForm = () => setTogglebtn(!togglebtn);

  const [mailConfirmation, setMailConfirmation] = useState(!true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [company, setCompany] = useState("");
  const [services, setServices] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch("https://9ubmaw6gj0.execute-api.ap-south-1.amazonaws.com/email", {
      mode: "no-cors",
      method: "POST",

      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNo: phoneNo,
        company: company,
        services: services,
        message: message,
      }),
    });
    setMailConfirmation(true);
  };

  return (
    <>
      <div className={togglebtn ? "stickyForm active" : "stickyForm"}>
        <div className="openbtn" onClick={showForm}>
          <h6>
            Contact Us
            <span>
              <BiMessageDetail />
            </span>
          </h6>
        </div>
        <div className="contactfrom">
          <div className="closebtn" onClick={showForm}>
            X
          </div>

          <form
            onSubmit={handleSubmit}
            className={mailConfirmation ? "active" : ""}
          >
            <h3>Contact Us</h3>
            <p>Please get in touch using the form below</p>
            <div
              className={
                mailConfirmation ? "success-message active" : "success-message"
              }
            >
              <h4>
                Thank you for contacting RedTeamFactory, our expert team will
                respond to you shortly
                <span onClick={showForm}>Close</span>
              </h4>
            </div>
            <div className="name">
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="firstname"
                placeholder="First name *"
                required
              />
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="lastname"
                placeholder="Last name *"
                required
              />
            </div>
            <div className="mail">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Business email address *"
                required
              />
            </div>
            <div className="num">
              <input
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                type="tel"
                placeholder="Phone number *"
                required
              />
              <input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                type="tel"
                placeholder="Company name*"
                required
              />
            </div>
            <div className="services">
              <select
                value={services}
                onChange={(e) => setServices(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select a Service..
                </option>
                <option value="Offensive Security">Offensive Security</option>
                <option value="Penetration Testing">Penetration Testing</option>
                <option value="Managed Security">Managed Security</option>
                <option value="Security Assessment">Security Assessment</option>
                <option value="Bug Bounty">Bug Bounty</option>
              </select>
            </div>
            <div className="message">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                cols="44"
                rows="5"
                placeholder="How can We help you? *"
                required
              ></textarea>
            </div>
            <div className="submit-button">
              <input type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
