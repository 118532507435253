import React from "react";

// StyleSheet
import "./Divider.scss";

export default function SecDivider() {
  return (
    <>
      <div className="divider">
        <hr />
      </div>
    </>
  );
}
