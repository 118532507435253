import React from "react";

// Stylesheet
import "./AssessmentSec.scss";

// Image src
import Img01 from "../../../img/security-assessment/image-01.png";

export default function SectionTwo() {
  return (
    <>
      <div className="sectionTwo-block-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="full-image" data-aos="fade-in">
                <img src={Img01} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 details">
              <div className="sub-title" data-aos="fade-in">
                <h6>// OVERVIEW</h6>
              </div>
              <div className="title" data-aos="fade-in">
                <h2>Your Partner for Security Innovation</h2>
              </div>
              <div className="content" data-aos="fade-in">
                <p>
                  Security Assessments are a very usual and legacy process
                  carried out by several organizations for a very long time. We
                  provide different types of security assessments for
                  organizations to ease their job in understanding the basic
                  level of security threats they would face. Most of the times
                  these are compliance requirements or whenever a change in
                  system occurs. Our security assessments are intended to
                  develop metrics to set cyber security maturity level
                  baselines, and to measure information security management
                  system capabilities against future state capabilities to
                  define an organization’s business requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
