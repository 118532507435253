import React from "react";

// StyleSheet
import "./ManagedSec.scss";

// image src
import Icon_01 from "../../../img/managed-security/lack-of-visibility&control.png";
import Icon_02 from "../../../img/managed-security/data-breaches&downtime.png";
import Icon_03 from "../../../img/managed-security/compliance-complexity.png";
import Icon_04 from "../../../img/managed-security/insecure-interfaces&apis.png";
import Icon_05 from "../../../img/managed-security/cloud-service-hijacking.png";
import Icon_06 from "../../../img/managed-security/share-technology-vulnerabilities.png";
import Icon_07 from "../../../img/managed-security/inadequate-access-management.png";
import Icon_08 from "../../../img/managed-security/misconfiguration.png";
import Icon_09 from "../../../img/managed-security/cloud-migrations.png";

export default function SectionOne() {
  return (
    <>
      <div className="section-one-block-one">
        <div className="container">
          <div className="row">
            <div className="title">
              <h2>Managed Security Solutions</h2>
            </div>
            <div className=" col-lg-5 col-md-12 col-sm-12 col-xs-12 center-align">
              <div className="content">
                <p>
                  Our Managed security solutions involve the procedures,
                  policies and technology that secure organizations broad
                  spectrum of environments against both external and insider
                  threats. We use Micro-segmentation technique which divides the
                  complete digital infrastructure into distinct security
                  segments down to the individual workload level. This technique
                  allows us to define flexible security implementations,
                  orchestration, automation, compliance and monitoring to
                  provide a precise solution to our customers. Moreover our
                  managed security solutions help organizations expand their
                  capabilities while minimizing their capex and opex. Our
                  solutions are designed to cover most of your information
                  security needs – from consulting to solutioning and
                  implementing.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="icon-section">
                <div className="sub-title">
                  <h5>Organizational Challenges - Managed</h5>
                </div>

                <div className="icons">
                  <div className="box">
                    <div className="inner-box">
                      <span>
                        <img src={Icon_01} alt="" />
                      </span>
                      <span>
                        <h6>
                          Lack of Visibility <br />
                          &amp; Control
                        </h6>
                      </span>
                    </div>
                    <div className="inner-box">
                      <span>
                        <img src={Icon_04} alt="" />
                      </span>
                      <span>
                        <h6>
                          Insecure Interfaces <br /> &amp; API's
                        </h6>
                      </span>
                    </div>
                    <div className="inner-box">
                      <span>
                        <img src={Icon_07} alt="" />
                      </span>
                      <span>
                        <h6>
                          Inadequate Access <br /> Management
                        </h6>
                      </span>
                    </div>
                  </div>
                  <div className="box">
                    <div className="inner-box">
                      <span>
                        <img src={Icon_02} alt="" />
                      </span>
                      <span>
                        <h6>
                          Data Breaches &amp; <br /> Downtime
                        </h6>
                      </span>
                    </div>
                    <div className="inner-box">
                      <span>
                        <img src={Icon_05} alt="" />
                      </span>
                      <span>
                        <h6>
                          Cloud Service <br /> Hijacking
                        </h6>
                      </span>
                    </div>
                    <div className="inner-box">
                      <span>
                        <img src={Icon_08} alt="" />
                      </span>
                      <span>
                        <h6>Misconfiguration</h6>
                      </span>
                    </div>
                  </div>
                  <div className="box">
                    <div className="inner-box">
                      <span>
                        <img src={Icon_03} alt="" />
                      </span>
                      <span>
                        <h6>
                          Compliance <br /> Complexity
                        </h6>
                      </span>
                    </div>
                    <div className="inner-box">
                      <span>
                        <img src={Icon_06} alt="" />
                      </span>
                      <span>
                        <h6>
                          Share Technology <br /> Vulnerabilities
                        </h6>
                      </span>
                    </div>
                    <div className="inner-box">
                      <span>
                        <img src={Icon_09} alt="" />
                      </span>
                      <span>
                        <h6>
                          Cloud <br /> Migrations
                        </h6>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
