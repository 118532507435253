import React from "react";
import { HashLink } from "react-router-hash-link";

// Image
import Logo from "../../img/layout/logo.png";

// Icon
// import {
//   FaLinkedinIn,
//   FaGooglePlusG,
//   FaTwitter,
//   FaFacebookF,
// } from "react-icons/fa";

// StyleSheet
import "./LayoutStyle.scss";
export default function Footer({ setToggleState, setToggle }) {
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const swapTab = (index) => {
    setToggle(index);
    setToggleState(index);
  };

  const ls = 1100;
  const ms = 770;
  const ss = 500;
  const xs = 499;

  var screenWidth = document.body.scrollWidth;
  // console.log(screenWidth);

  const penetrationScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1700);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2200);
    }
  };

  const managedScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1300);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1500);
    } else if (screenWidth <= xs) {
      window.scroll(0, 1600);
    }
  };
  const assessmentScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 2200);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1900);
    } else if (screenWidth >= ss) {
      window.scroll(0, 2400);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2500);
    }
  };

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div className="foo-logo">
                <HashLink exact to="/#" className="logo">
                  <img src={Logo} alt="logo" />
                </HashLink>
              </div>
              <div className="foo-des">
                <p>
                  RedTeam Factory Solutions Pvt Ltd stands out of the box from
                  the legacy security practices, our practices are implemented
                  precisely by segmenting sector wise thus providing the right
                  solutions to the right segment.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 foo-menu">
              <div className="foo-menu-link">
                <h5>Company</h5>
                <ul className="sub-menu">
                  <li>
                    <HashLink exact to="/company#about" className="nav-link">
                      About
                    </HashLink>
                  </li>
                  <li>
                    <HashLink exact to="/company#awards" className="nav-link">
                      Awards &amp; Recognition
                    </HashLink>
                  </li>
                  <li>
                    <HashLink exact to="/company#clients" className="nav-link">
                      Clients &amp; Partners
                    </HashLink>
                  </li>
                  <li>
                    <HashLink exact to="/company#partners" className="nav-link">
                      Community Partners
                    </HashLink>
                  </li>
                </ul>
              </div>
              <div className="foo-menu-link">
                <h5>Managed Security</h5>
                <ul className="sub-menu">
                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(1);
                        managedScrollTo();
                      }}
                    >
                      Cloud Security
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(2);
                        managedScrollTo();
                      }}
                    >
                      SOC as Service
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(3);
                        managedScrollTo();
                      }}
                    >
                      Blue Teaming
                    </HashLink>
                  </li>

                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(4);
                        managedScrollTo();
                      }}
                    >
                      Intel Solution
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(5);
                        managedScrollTo();
                      }}
                    >
                      Dev Sec Ops
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(6);
                        managedScrollTo();
                      }}
                    >
                      Compliance Implementation
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/managed-security"
                      className="nav-link"
                      onClick={() => {
                        swapTab(7);
                        managedScrollTo();
                      }}
                    >
                      Affiliated Services
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 foo-menu">
              <div className="foo-menu-link">
                <h5>Offensive Security</h5>
                <ul className="sub-menu">
                  <li>
                    <HashLink
                      to="/offensive-security#Services"
                      className="nav-link"
                      onClick={() => swapTab(1)}
                    >
                      Cyber Red Team
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/offensive-security#Services"
                      className="nav-link"
                      onClick={() => swapTab(2)}
                    >
                      Cyber Purple Team
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/offensive-security#Services"
                      className="nav-link"
                      onClick={() => swapTab(3)}
                    >
                      R &amp; D
                    </HashLink>
                  </li>
                </ul>
              </div>
              <div className="foo-menu-link">
                <h5>Security Assessment</h5>
                <ul className="sub-menu">
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(1);
                        assessmentScrollTo();
                      }}
                    >
                      Risk Assessment
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(2);
                        assessmentScrollTo();
                      }}
                    >
                      Compromise Assessment
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(3);
                        assessmentScrollTo();
                      }}
                    >
                      Security Configuration Assessment
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(4);
                        assessmentScrollTo();
                      }}
                    >
                      Vulnerability Assessment
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(5);
                        assessmentScrollTo();
                      }}
                    >
                      Social Engineering Assessment
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(6);
                        assessmentScrollTo();
                      }}
                    >
                      Compliance based Assessment
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/security-assessment"
                      className="nav-link"
                      onClick={() => {
                        toggleTab(7);
                        assessmentScrollTo();
                      }}
                    >
                      OSINT
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 foo-menu">
              <div className="foo-menu-link">
                <h5>Penetration Testing</h5>
                <ul className="sub-menu">
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(1);
                        penetrationScrollTo();
                      }}
                    >
                      Network Infrastructure
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(2);
                        penetrationScrollTo();
                      }}
                    >
                      Cloud Infrastructure
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(3);
                        penetrationScrollTo();
                      }}
                    >
                      Critical Infrastructure
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(4);
                        penetrationScrollTo();
                      }}
                    >
                      Wireless Network
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(5);
                        penetrationScrollTo();
                      }}
                    >
                      Web Application
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(6);
                        penetrationScrollTo();
                      }}
                    >
                      Mobile Application
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/penetration-testing"
                      className="nav-link"
                      onClick={() => {
                        swapTab(7);
                        penetrationScrollTo();
                      }}
                    >
                      IOT/IIOT
                    </HashLink>
                  </li>
                </ul>
              </div>

              <div className="foo-menu-link">
                <h5>Innovations</h5>
                <ul className="sub-menu">
                  <li>
                    <HashLink to="/innovations#" className="nav-link">
                      BugDiscover
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/innovations/#inno-sec-five"
                      className="nav-link"
                    >
                      CODINT (coming soon)
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="/innovations/#inno-sec-five"
                      className="nav-link"
                    >
                      Breach Panda (coming soon)
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row ">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 copy">
              <h5>
                Copyrights &copy; 2022
                <HashLink exact to="/#">
                  {" "}
                  RedTeamFactory Solutions Pvt. Ltd.
                </HashLink>
              </h5>
            </div>
            {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="social-icon">
                <a href="#" className="icon-link">
                  <FaLinkedinIn className="icon" />
                </a>
                <a href="#" className="icon-link">
                  <FaGooglePlusG className="icon" />
                </a>
                <a href="#" className="icon-link">
                  <FaTwitter className="icon" />
                </a>
                <a href="#" className="icon-link">
                  <FaFacebookF className="icon" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
