import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";

// StyleSheet
import "./Totop.scss";

export default function Totop() {
  const [totop, setTotop] = useState(!true);
  const toTopBtn = () => {
    if (window.scrollY >= 1000) {
      setTotop(true);
    } else setTotop(false);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  window.addEventListener("scroll", toTopBtn);
  return (
    <>
      <div className={totop ? "totop active" : "totop"} onClick={scrollToTop}>
        <FaArrowUp />
      </div>
    </>
  );
}
