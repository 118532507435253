import React, { useEffect, useState } from "react";

// StyleSheet
import "./InnovationsSec.scss";

// Parallax
import Parallax from "./Parallax";

export default function SectionOne() {
  return (
    <>
      <div className="innovations-sectionOne-block-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <Parallax />
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm12- col-xs-12 center-align">
              <div className="title">
                <h2>Security Empowered Through Community</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
