import React from "react";

// StyleSheet
import "./PenetrationSec.scss";

// image src
import Formula from "../../../img/penetration-testing/formula.png";
import Icon01_01 from "../../../img/penetration-testing/Vulnerability-Analysis-icon.png";
import Icon01_02 from "../../../img/penetration-testing/Exploitation-icon.png";
import Icon01_03 from "../../../img/penetration-testing/Post-Exploitation-icon.png";
import Icon01_04 from "../../../img/penetration-testing/Reporting-icon.png";
import Icon01_05 from "../../../img/penetration-testing/Information-Gathering-icon.png";
import Icon01_06 from "../../../img/penetration-testing/Threat-Modeling-icon.png";

export default function SectionThree() {
  return (
    <>
      <div className="pen-section-three-block">
        <div className="container">
          <div className="title">
            <h2>Our Approach</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="content">
                <p>
                  The complete approach is carried out with focus to define the
                  scope of threat landscape on the target. All possible tools,
                  techniques and practices are carried out to achieve the goal.{" "}
                </p>
              </div>
              <div className="formula">
                <img src={Formula} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="life-cycle">
                <div className="block-one">
                  <div className="circle">
                    <img src={Icon01_01} alt="" />
                    <p>
                      Vulnerability <br />
                      Analysis
                    </p>
                  </div>
                  <span></span>
                  <div className="circle">
                    <img src={Icon01_02} alt="" />
                    <p>Exploitation</p>
                  </div>
                  <span></span>
                  <div className="circle">
                    <img src={Icon01_03} alt="" />
                    <p>
                      Post <br /> Exploitation
                    </p>
                  </div>
                </div>
                <div className="block-two">
                  <span></span>
                  <h6>
                    VAPT <br /> Life Cycle
                  </h6>
                  <span></span>
                </div>
                <div className="block-three">
                  <div className="circle">
                    <img src={Icon01_04} alt="" />
                    <p>Reporting</p>
                  </div>
                  <span></span>
                  <div className="circle">
                    <img src={Icon01_05} alt="" />
                    <p>
                      Information <br />
                      Gathering
                    </p>
                  </div>
                  <span></span>
                  <div className="circle">
                    <img src={Icon01_06} alt="" />
                    <p>
                      Threat <br />
                      Modeling
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
