import React from "react";

// image Src
import Icon01 from "../../../img/security-assessment/Risk-Assessment-icon.png";
import Icon02 from "../../../img/security-assessment/Compromise-Assessment-icon.png";
import Icon03 from "../../../img/security-assessment/Security-Configuration-Assessment--icon.png";
import Icon04 from "../../../img/security-assessment/Vulnerability-Assessment-icon.png";
import Icon05 from "../../../img/security-assessment/Social-Engineering-Assessment-icon.png";
import Icon06 from "../../../img/security-assessment/Compliance-based-Assessment-icon.png";
import Icon07 from "../../../img/security-assessment/OSINT-icon.png";

// Stylesheet
import "./AssessmentSec.scss";

// Slick Js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

export default function SectionFour({ toggleState, setToggleState }) {
  const toggleTab = (index) => {
    setToggleState(index);
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="sectionFour-block-one">
        <div className="container">
          <div className="sub-title">
            <h6>// OUR SOLUTIONS</h6>
          </div>
          <div className="title">
            <h2>We Offer a Wide Variety of Security Assessment</h2>
          </div>
          <div className="row">
            <div className="col-12 tab-sec">
              <Slider {...settings}>
                <div
                  className={toggleState === 1 ? "box  current" : "box"}
                  onClick={() => toggleTab(1)}
                >
                  <img src={Icon01} alt="" />
                  <h6>
                    Risk
                    <br />
                    Assessment
                  </h6>
                </div>
                <div
                  className={toggleState === 2 ? "box current" : "box"}
                  onClick={() => toggleTab(2)}
                >
                  <img src={Icon02} alt="" />
                  <h6>
                    Compromise
                    <br />
                    Assessment
                  </h6>
                </div>
                <div
                  className={toggleState === 3 ? "box current" : "box"}
                  onClick={() => toggleTab(3)}
                >
                  <img src={Icon03} alt="" />
                  <h6>
                    Security Configuration
                    <br />
                    Assessment
                  </h6>
                </div>
                <div
                  className={toggleState === 4 ? "box current" : "box"}
                  onClick={() => toggleTab(4)}
                >
                  <img src={Icon04} alt="" />
                  <h6>
                    Vulnerability
                    <br />
                    Assessment
                  </h6>
                </div>
                <div
                  className={toggleState === 5 ? "box current" : "box"}
                  onClick={() => toggleTab(5)}
                >
                  <img src={Icon05} alt="" />
                  <h6>
                    Social Engineering
                    <br />
                    Assessment
                  </h6>
                </div>
                <div
                  className={toggleState === 6 ? "box current" : "box"}
                  onClick={() => toggleTab(6)}
                >
                  <img src={Icon06} alt="" />
                  <h6>
                    Compliance Based
                    <br />
                    Assessment
                  </h6>
                </div>
                <div
                  className={toggleState === 7 ? "box current" : "box"}
                  onClick={() => toggleTab(7)}
                >
                  <img src={Icon07} alt="" />
                  <h6>OSINT</h6>
                </div>
              </Slider>
            </div>
          </div>
          <div className="row" data-aos="zoom-in">
            <div
              className={
                toggleState === 1
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div className="col-lg-7 col-md-8 col-sm-12 text">
                <div className="title">
                  <h2>Risk Assessment </h2>
                </div>
                <div className="content" id="what-we-do-01">
                  <p>
                    Organizations technical assets have continuous changes due
                    to different factors like development activities, change
                    requests, business changes and much more. Our Risk
                    Assessments are carried out periodically to evaluate the
                    policy, procedure, implementations to understand the risks
                    and to remediate it on timely basis. Our Risk Assessment
                    services are carried out to identify potential loss, damage
                    or destruction of an asset as a result of a threat
                    exploiting vulnerability.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon01} alt="" />
              </div>
            </div>

            <div
              className={
                toggleState === 2
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div className="col-lg-7 col-md-8 col-sm-12 text">
                <div className="title" id="what-we-do-01">
                  <h2>Compromise Assessment</h2>
                </div>
                <div className="content">
                  <p>
                    Organizations are unaware about a breach of their maintained
                    IT asset or a intrusion by cyber criminals. Our Compromise
                    Assessment is carried out to identify ongoing or past
                    attacker activity in an organization’s environment. The
                    assessment plan is to examine organization’s IT
                    infrastructure with a focus on identifying signs of a
                    potential compromise, current breach activity or malicious
                    tools that an attacker could initiate later. Compromise
                    assessments benefits organizations to minimize dwell time,
                    reduce risk and improve security.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon02} alt="" />
              </div>
            </div>
            <div
              className={
                toggleState === 3
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div
                className="col-lg-7 col-md-8 col-sm-12 text"
                data-aos="fade-down"
              >
                <div className="title" id="what-we-do-01">
                  <h2>Security Configuration Assessment</h2>
                </div>
                <div className="content">
                  <p>
                    Organizations use several security tools to build their
                    defense, if these tools are not sufficiently configured or
                    left unmonitored for patches, could allow cyber criminals to
                    bypass security and create damages. Our assessment over the
                    defenses of an organization are designed to detect breach
                    techniques and unsafe configuration enabling us to optimize
                    security tools & products.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon03} alt="" />
              </div>
            </div>
            <div
              className={
                toggleState === 4
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div
                className="col-lg-7 col-md-8 col-sm-12 text"
                data-aos="fade-down"
              >
                <div className="title">
                  <h2>Vulnerability Assessment </h2>
                </div>
                <div className="content">
                  <p>
                    Organizations have technologies such as Applications,
                    Network, Cloud and much more exposed to different types of
                    users and these technologies are updated or changed in an
                    irregular period. These technologies might be vulnerable and
                    it is mandatory to do a vulnerability assessment in a
                    periodic manner. Our Vulnerability assessment services are
                    carried out to identify the sensitive vulnerabilities in a
                    security program that can be exploited by threats to gain
                    unauthorized access to an asset.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon04} alt="" />
              </div>
            </div>
            <div
              className={
                toggleState === 5
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div
                className="col-lg-7 col-md-8 col-sm-12 text"
                data-aos="fade-down"
              >
                <div className="title">
                  <h2>Social Engineering Assessment </h2>
                </div>
                <div className="content">
                  <p>
                    Cyber-attacks are not always focused on the technologies.
                    People continue to be one of the weakest links in an
                    organization. Our assessment includes a wide range of
                    engagements designed to reveal the ability of systems and
                    personnel to detect and respond to a simulated attack
                    exercise.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon05} alt="" />
              </div>
            </div>
            <div
              className={
                toggleState === 6
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div
                className="col-lg-7 col-md-8 col-sm-12 text"
                data-aos="fade-down"
              >
                <div className="title">
                  <h2>Compliance Based Assessment</h2>
                </div>
                <div className="content">
                  <p>
                    Organizations comply with different types of compliances and
                    it requires a set of third-party assessment focusing the
                    specific standards. Our compliance-based assessment services
                    include PCIDSS, HIPPA, ISO, GDPR and much more. The
                    assessment is carried out similarly to other vertical but
                    not limited to final deliverables which will cover all the
                    elements relevant to compliance affecting vectors and
                    remediation.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon06} alt="" />
              </div>
            </div>
            <div
              className={
                toggleState === 7
                  ? "tab-sec-des row  current"
                  : "tab-sec-des row"
              }
            >
              <div
                className="col-lg-7 col-md-8 col-sm-12 text"
                data-aos="fade-down"
              >
                <div className="title">
                  <h2>OSINT</h2>
                </div>
                <div className="content">
                  <p>
                    Our OSINT service is a process of analysis to identify
                    increasing volume of valuable information available on the
                    Internet about an individual, group or an organization. We
                    identify open sources of information about an entity to
                    protect from disseminated bad actors. Our OSINT service is
                    designed to gather intelligence from publicly available
                    information sources which include both Clearnet & Darknet.
                    Our OSINT Intel report will provide all information about
                    what a bad actor could reach and also provides remediation
                    to avoid threat occurrences.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 image">
                <img src={Icon07} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
