import React from "react";

// Image src
import Image01 from "../../../img/Inovation/Img-01.svg";
import Icon_01 from "../../../img/Inovation/icon-01.png";
import Img_02 from "../../../img/Inovation/img-07.png";

export default function SectionFour() {
  return (
    <>
      <div className="innovations-sectionFour-block-one">
        <div className="container">
          <div className="title">
            <h2 className="mixed-sec">
              How <img src={Img_02} alt="" /> Works
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 list">
              <ul>
                <li>
                  <span>
                    <img src={Icon_01} alt="" />
                  </span>
                  <p>
                    BugDiscover connects with company program manager and
                    launches a program (Open Discover/Private Discover)
                  </p>
                </li>

                <li>
                  <span>
                    <img src={Icon_01} alt="" />
                  </span>
                  <p>Security researchers are given access to the program</p>
                </li>
                <li>
                  <span>
                    <img src={Icon_01} alt="" />
                  </span>
                  <p>
                    Security researchers start researching for vulnerabilities
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Icon_01} alt="" />
                  </span>
                  <p>
                    Once a Bug/Vulnerability is found, researchers submit the
                    report to that specific program
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Icon_01} alt="" />
                  </span>
                  <p>
                    BugDiscover Program manager filters the valid report
                    submission and sends to the company
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Icon_01} alt="" />
                  </span>
                  <p>Company fixes the Vulnerability reported</p>
                </li>
              </ul>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 center-align">
              <div className="full-img">
                <img src={Image01} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
