import React from "react";

// Svg
import Lottie from "lottie-react";
import Svg from "../../../svg/circle-moving.json";

// Image
import Icon1 from "../../../img/home/icon-01.png";
import Icon2 from "../../../img/home/icon-04.png";
import Icon3 from "../../../img/home/icon-02.png";
import Icon4 from "../../../img/home/icon-03.png";
import Icon5 from "../../../img/home/icon-05.png"

// StyleSheet
import "./HomeSec.scss";

export default function SectionFivePc() {
  return (
    <>
      <div className="home-section-five">
        <div className="container">
          <div className="title">
            <h2>Why Red Team Factory</h2>
          </div>
          <div className="pc-view">
            <PcView />
          </div>
          <div className="tab-view">
            <TabView />
          </div>
        </div>
      </div>
    </>
  );
}

function PcView() {
  return (
    <>
      <div className="full-svg">
        <Lottie animationData={Svg} loop={true} />
      </div>
      <div className="content">
        <div className="row align-center">
          <div className="col-4 left-section">
            <div className="inner-section">
              <div className="box">
                <p>
                  We are one of the first sources on the planet to be notified
                  of a current market threat
                </p>
                <span>
                  <img src={Icon1} alt="" />
                </span>
              </div>
              <div className="box">
                <p>We understand how malicious hackers' function</p>
                <span>
                  <img src={Icon3} alt="" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-4 mid-section">
            <p>
              We believe that security is well established today, but that it is
              not supplied in an proper manner, thus we aim to deliver it in an
              appropriate manner
            </p>
          </div>
          <div className="col-4 right-section">
            <div className="inner-section">
              <div className="box">
                <span>
                  <img src={Icon2} alt="" />
                </span>
                <p>
                  We have established a Crowd Sourced Security Researcher
                  community with which we are closely associated
                </p>
              </div>
              <div className="box">
                <span>
                  <img src={Icon4} alt="" />
                </span>
                <p>
                  Our process employs a one-of-a-kind method for planning and
                  modelling threats
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TabView() {
  return (
    <>
      <div className="box">
        <span>
          <img src={Icon1} alt="" />
        </span>
        <p>
          We are one of the first sources on the planet to be notified of a
          current market threat
        </p>
      </div>

      <div className="box">
        <span>
          <img src={Icon3} alt="" />
        </span>
        <p>We understand how malicious hackers' function</p>
      </div>

      <div className="box">
        <span>
          <img src={Icon5} alt="" />
        </span>
        <p>
          We believe that security is well established today, but that it is not
          supplied in an proper manner, thus we aim to deliver it in an
          appropriate manner
        </p>
      </div>

      <div className="box">
        <span>
          <img src={Icon2} alt="" />
        </span>
        <p>
          We have established a Crowd Sourced Security Researcher community with
          which we are closely associated
        </p>
      </div>
      <div className="box">
        <span>
          <img src={Icon4} alt="" />
        </span>
        <p>
          Our process employs a one-of-a-kind method for planning and modelling
          threats
        </p>
      </div>
    </>
  );
}
