import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

// StyleSheet
import "./ManagedSec.scss";

// image src

import Icon_01_01 from "../../../img/managed-security/devsecops.png";
import Icon_01_02 from "../../../img/managed-security/VAPT&security-assessments.png";
import Icon_01_03 from "../../../img/managed-security/security-incident&event-management.png";
import Icon_01_04 from "../../../img/managed-security/identi&access-management.png";
import Icon_01_05 from "../../../img/managed-security/continuous-security-monitoring.png";
import Icon_01_06 from "../../../img/managed-security/compliance-support.png";

import Icon_01_07 from "../../../img/managed-security/saas.png";
import Icon_01_08 from "../../../img/managed-security/paas.png";
import Icon_01_09 from "../../../img/managed-security/iaas.png";

import Icon_02_01 from "../../../img/managed-security/continuous-security-monitoring.png";
import Icon_02_02 from "../../../img/managed-security/thread-intelligence.png";
import Icon_02_03 from "../../../img/managed-security/asset-threat-monitoring.png";
import Icon_02_04 from "../../../img/managed-security/soc-maturity-improvement.png";
import Icon_02_05 from "../../../img/managed-security/incident-response-management.png";
import Icon_02_06 from "../../../img/managed-security/OSINT.png";

import Icon_03_01 from "../../../img/managed-security/Incident-scope.png";
import Icon_03_02 from "../../../img/managed-security/escalation-matrix-plan&automation.png";
import Icon_03_03 from "../../../img/managed-security/real-time-corrective-actions.png";
import Icon_03_04 from "../../../img/managed-security/collection-of-evidence.png";
import Icon_03_05 from "../../../img/managed-security/recommendations&SOC-maturity-programs.png";
import Icon_03_06 from "../../../img/managed-security/comprehensive-incident-reporting.png";

import Icon_04_01 from "../../../img/managed-security/thread-intelligence.png";
import Icon_04_02 from "../../../img/managed-security/cyber-kill-chain.png";
import Icon_04_03 from "../../../img/managed-security/OSINT.png";
import Icon_04_04 from "../../../img/managed-security/threat-hunting.png";
import Icon_04_05 from "../../../img/managed-security/asset-intel&alerting.png";

import Icon_05_01 from "../../../img/managed-security/De-Risk-Releases.png";
import Icon_05_02 from "../../../img/managed-security/compliance-support.png";
import Icon_05_03 from "../../../img/managed-security/Protect-Data.png";
import Icon_05_04 from "../../../img/managed-security/Accelerate-Releases.png";
import Icon_05_05 from "../../../img/managed-security/Improve-Quality.png";
import Icon_05_06 from "../../../img/managed-security/Test-Intelligently.png";

import Icon_06_01 from "../../../img/managed-security/gdpr.png";
import Icon_06_02 from "../../../img/managed-security/soc.png";
import Icon_06_03 from "../../../img/managed-security/iso.png";
import Icon_06_04 from "../../../img/managed-security/hipaa.png";
import Icon_06_05 from "../../../img/managed-security/pci-dss.png";
import Icon_06_06 from "../../../img/managed-security/nist.png";
import Icon_06_07 from "../../../img/managed-security/fedramp.png";

import Icon_07_01 from "../../../img/managed-security/Cloud Infra Management.png";
import Icon_07_02 from "../../../img/managed-security/Source Code Management.png";
import Icon_07_03 from "../../../img/managed-security/Solutions Architect.png";
import Icon_07_04 from "../../../img/managed-security/devsecops.png";
import Icon_07_05 from "../../../img/managed-security/Brand Management & Monitoring.png";
import Icon_07_06 from "../../../img/managed-security/Digital Marketing Services.png";
import Icon_07_07 from "../../../img/managed-security/Website - UI-UX Development.png";
import Icon_07_08 from "../../../img/managed-security/Web App & Mobile App Development.png";
import Icon_07_09 from "../../../img/managed-security/Resource Consulting.png";

import Image_01 from "../../../img/managed-security/img-01.png";
import Image_02 from "../../../img/managed-security/img-02.png";
import Image_03 from "../../../img/managed-security/img-03.png";

export default function SectionTwo({ toggleState, setToggleState }) {
  // const [tabLink, setTabLink] = useState(1);
  const swapTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
      <div className="section-two-block-one">
        <div className="tab-nav" id="services">
          <ul>
            <li
              className={toggleState === 1 ? "active" : ""}
              onClick={() => swapTab(1)}
            >
              Cloud Security
            </li>
            <li
              className={toggleState === 2 ? "active" : ""}
              onClick={() => swapTab(2)}
            >
              SOC as Service
            </li>
            <li
              className={toggleState === 3 ? "active" : ""}
              onClick={() => swapTab(3)}
            >
              Blue Teaming
            </li>
            <li
              className={toggleState === 4 ? "active" : ""}
              onClick={() => swapTab(4)}
            >
              Intel Solution
            </li>
            <li
              className={toggleState === 5 ? "active" : ""}
              onClick={() => swapTab(5)}
            >
              Dev Sec Ops
            </li>
            <li
              className={toggleState === 6 ? "active" : ""}
              onClick={() => swapTab(6)}
            >
              Compliance Implementation
            </li>
            <li
              className={toggleState === 7 ? "active" : ""}
              onClick={() => swapTab(7)}
            >
              Affiliated Services
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="row">
            <div
              className={
                toggleState === 1 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>Cloud Security </h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Our Cloud Security services are blended with perfect
                      planning and implementation which includes analysis of
                      sensitivity to risks, cloud service models, varied levels
                      of service, consideration of proper cloud type, data
                      storage and transfer mechanism. Our solutions are custom
                      built as per the clients business and technology operating
                      model.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="sub-title">
                    <h5>Core Activities</h5>
                  </div>
                  <div className="icon-section">
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_01_01} alt="" />
                        </span>
                        <span>
                          <h6>DevSecOps</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_01_05} alt="" />
                        </span>
                        <span>
                          <h6>
                            Continuous <br /> Security Monitoring
                          </h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_01_03} alt="" />
                        </span>
                        <span>
                          <h6>
                            Security Incident <br />
                            &amp; Event Management
                          </h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_01_04} alt="" />
                        </span>
                        <span>
                          <h6>
                            Identity &amp; <br /> Access Management
                          </h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_01_02} alt="" />
                        </span>
                        <span>
                          <h6>
                            VAPT &amp; Security <br /> Assessments
                          </h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_01_06} alt="" />
                        </span>
                        <span>
                          <h6>
                            Compliance <br /> Support
                          </h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Breaking The Myth</h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Cloud is a shared responsibillity when it comes to
                      security. Though the cloud service providers deliver
                      important elements of security, the organization also have
                      their responsibillity to ensure full security is
                      delivered. Cloud security requires a different strategic
                      mode from the legacy model as per the shared
                      responsibillity policy of the cloud service provider.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="sub-title">
                    <h5>
                      Major responsibillity when it comes to public cloud
                      service providers
                    </h5>
                  </div>
                  <div className="sub-icon-section">
                    <div className="box">
                      <span>
                        <img src={Icon_01_07} alt="" />
                      </span>
                      <span>
                        <p>
                          <b>SaaS</b> - Customers are responsible for securing
                          their data and user access.
                        </p>
                      </span>
                    </div>
                    <div className="box">
                      <span>
                        <img src={Icon_01_08} alt="" />
                      </span>
                      <span>
                        <p>
                          <b>PaaS</b> - Customers are responsible for securing
                          their user access, data and applications.
                        </p>
                      </span>
                    </div>
                    <div className="box">
                      <span>
                        <img src={Icon_01_09} alt="" />
                      </span>
                      <span>
                        <p>
                          <b>IaaS</b> - Customers are responsible for securing
                          operating systems, virtual network traffic, data, user
                          access and applications.
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 2 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>SOC as Service </h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Our SOC as service is a complete suite of defensive
                      solutions which portrays your preparedness for an attack
                      or a breach. We are specialized in building unique
                      defenses and response handling setups for organizations.
                      We understand requirement at each stack level operations,
                      this includes not just focusing the security logs and
                      event from different sources of IT infrastructure but also
                      the business model, technology, people, layers of
                      hierarchy and frameworks of the organizations. We
                      determine process, procedures and implementations by
                      exclusive threat modelling, In housed techniques and
                      industrial standards.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="sub-title">
                    <h5>Core Activities</h5>
                  </div>
                  <div className="icon-section">
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_02_01} alt="" />
                        </span>
                        <span>
                          <h6>Security Monitoring</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_02_02} alt="" />
                        </span>
                        <span>
                          <h6>Threat Intelligence</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_02_03} alt="" />
                        </span>
                        <span>
                          <h6>Asset Threat Monitoring</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_02_04} alt="" />
                        </span>
                        <span>
                          <h6>SOC Maturity Improvement</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_02_05} alt="" />
                        </span>
                        <span>
                          <h6>
                            Incident Response <br /> Management
                          </h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_02_06} alt="" />
                        </span>
                        <span>
                          <h6>Cyber Kill Chain</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cycle-process">
                <div className="box">
                  <div className="overlay">
                    <div className="inner-box">
                      <h6>
                        EVENT <br /> <span>Initial Event</span>
                      </h6>
                    </div>
                    <hr className="inner-line" />
                    <div className="inner-box">
                      <h6>
                        EVENT ANALYSIS <br />
                        <span>Triage (Detect Time)</span>
                      </h6>
                    </div>
                    <hr className="inner-line" />
                    <div className="inner-box">
                      <h6>
                        REPORT <br /> <span>Report Time</span>
                      </h6>
                    </div>
                  </div>
                  <div className="name-tag">
                    <h6>Dwell Time</h6>
                  </div>
                </div>
                <hr className="outer-line" />
                <div className="box">
                  <div className="overlay">
                    <h6>
                      CONTAIN <br /> <span>IR Action</span>
                    </h6>
                  </div>
                  <div className="name-tag">
                    <h6>Contain Time</h6>
                  </div>
                </div>
                <hr className="outer-line" />
                <div className="box">
                  <div className="overlay">
                    <h6>
                      REMEDIATE <br /> <span>Time to Remediate</span>
                    </h6>
                  </div>
                  <div className="name-tag">
                    <h6>Business Impact</h6>
                  </div>
                </div>
              </div>
              <div className="formula">
                <h5>
                  Dwell Time + Contain Time = Unauthorized Access Time to Asset
                </h5>
              </div>
            </div>

            <div
              className={
                toggleState === 3 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>Blue Teaming </h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Our Blue Teaming services are designed to provide
                      proactive solutions that helps the organization to contain
                      the incident, eradicate the threat, remediate,
                      systematizing the ticketing systems and response handling.
                      Our Blue Team continuously harden organizations
                      technologies, Build SOP’s and update TTP’s to intel
                      systems. Our Blue Team constantly analyse the
                      organizations IT posture security standpoint, identify
                      existing flaws and confirm each security measures are
                      operational &amp; effective. Our techniques define
                      repeatable incident triage and investigation processes to
                      standardize the actions of a crewmember so that no
                      important tasks are left behind. Our Blue Team works
                      towards maturing the SOC program over a period of time to
                      build a full proof environment.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="sub-title">
                    <h5>Core Activities</h5>
                  </div>
                  <div className="icon-section">
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_03_01} alt="" />
                        </span>
                        <span>
                          <h6>
                            Incident Scope <br /> &amp; Handling
                          </h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_03_02} alt="" />
                        </span>
                        <span>
                          <h6>Escalation Matrix Plan &amp; Automation</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_03_03} alt="" />
                        </span>
                        <span>
                          <h6>Real Time Corrective Actions </h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_03_04} alt="" />
                        </span>
                        <span>
                          <h6>Collection of Evidence </h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_03_05} alt="" />
                        </span>
                        <span>
                          <h6>Recommendations &amp; SOC Maturity Programs</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_03_06} alt="" />
                        </span>
                        <span>
                          <h6>Comprehensive Incident Reporting</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="highlighted-words">
                <q>
                  We follow the most prominent incident response process model
                  like DOE/CIAC model
                </q>
              </div>
              <div className="step-process">
                <div className="box">
                  <h5>Preparation</h5>
                </div>
                <span></span>
                <div className="box">
                  <h5>Identification</h5>
                </div>
                <span></span>
                <div className="box">
                  <h5>Containment</h5>
                </div>
                <span></span>
                <div className="box">
                  <h5>Eradication</h5>
                </div>
                <span></span>
                <div className="box">
                  <h5>Recovery</h5>
                </div>
                <span></span>
                <div className="box">
                  <h5>
                    Lessons <br />
                    Learned
                  </h5>
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 4 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>Intel Solutions </h2>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Our Intel solutions are built to provide proactive add-ons
                      to the solution deployed on the clients cloud
                      infra-structure. Intel feeds are collected from different
                      sources correlated and integrated to the clients required
                      systems to fore see the activity of threat actors. We have
                      Industry specific segmented solutions since threats are
                      emerging specific to nature of business and its operating
                      model. Our Threat Intel setup is also extended to a Threat
                      Hunting model designed to perform hunting process aligning
                      with Intel received through different threat feed sources
                      and this technique will widely help organizations on
                      proactive defense measures which are usually integrated
                      with the Security solutions deployed. Our design gives a
                      broad access towards information of latest threats,
                      malware, exploits and attack vectors that are active. More
                      over our threat hunting labs allows performing deep
                      correlated investigations to find in-depth information of
                      an event that is looked for.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="full-image">
                    <img src={Image_01} alt="" />
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Core Activities</h2>
              </div>
              <div className="icon-section">
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_04_01} alt="" />
                    </span>
                    <span>
                      <h6>Threat Intelligence</h6>
                    </span>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_04_02} alt="" />
                    </span>
                    <span>
                      <h6>Threat Hunting</h6>
                    </span>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_04_03} alt="" />
                    </span>
                    <span>
                      <h6>Cyber Kill Chain</h6>
                    </span>
                  </div>
                </div>
              </div>
              <div className="icon-section center-align">
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_04_04} alt="" />
                    </span>
                    <span>
                      <h6>Asset Intel &amp; Alerting</h6>
                    </span>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_04_05} alt="" />
                    </span>
                    <span>
                      <h6>OSINT</h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 5 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>Dev Sec Ops</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Our DevSecOps solutions are designed to provide seamless
                      integrations of security into DevOps CI/CD workflow and
                      practice application security into earlier software
                      development life cycle. Our solutions also expands the
                      collaboration between development and operations team to
                      integrate security teams in the software delivery cycle.
                      Our experience in the field helps us build a change in
                      culture, process and tools across the core functional
                      teams and make security a shared responsibility. Our
                      Solutions will operate like developers to implement
                      security and compliance available to be consumed as
                      services. The core plan and process is to learn the
                      loopholes, look for weaknesses and stream line the process
                      to provide remediation actions avoiding queuing of
                      problems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="full-image">
                    <img src={Image_02} alt="" />
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Core Activities</h2>
              </div>
              <div className="icon-section">
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_05_01} alt="" />
                    </span>
                    <span>
                      <h6>De Risk Releases</h6>
                    </span>
                  </div>
                  <div className="inner-box">
                    <span>
                      <img src={Icon_05_02} alt="" />
                    </span>
                    <span>
                      <h6>Ensure Compliance</h6>
                    </span>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_05_03} alt="" />
                    </span>
                    <span>
                      <h6>Protect Data</h6>
                    </span>
                  </div>
                  <div className="inner-box">
                    <span>
                      <img src={Icon_05_04} alt="" />
                    </span>
                    <span>
                      <h6>Accelerate Releases</h6>
                    </span>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <span>
                      <img src={Icon_05_05} alt="" />
                    </span>
                    <span>
                      <h6>Improve Quality</h6>
                    </span>
                  </div>
                  <div className="inner-box">
                    <span>
                      <img src={Icon_05_06} alt="" />
                    </span>
                    <span>
                      <h6>Test Intelligently</h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 6 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>Compliance Implementation</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      Our Compliance as a Service goal is to reduce an
                      organization's compliance management liability by
                      extending our team to manage tasks required to meet
                      regulatory requirements in a more cost-effective manner or
                      to build and get readiness for a specific compliance. Our
                      solutions are designed to maintain and update the
                      organizational IT infra and its assets over time. On top
                      of the core Managed Security Services we also serve
                      organization on basic requirement for managing their
                      compliance, to know the gaps, assessments on limited
                      scopes and basic vulnerability scanning’s.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="sub-title">
                    <h5>Compliances We support</h5>
                  </div>
                  <div className="icon-section no-gap">
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_01} alt="" />
                        </span>
                        <span>
                          <h6>GDPR</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_02} alt="" />
                        </span>
                        <span>
                          <h6>SOC</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_03} alt="" />
                        </span>
                        <span>
                          <h6>ISO</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_04} alt="" />
                        </span>
                        <span>
                          <h6>HIPAA</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_05} alt="" />
                        </span>
                        <span>
                          <h6>PCI DSS</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_06} alt="" />
                        </span>
                        <span>
                          <h6>NIST</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box additional-icon">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_06_07} alt="" />
                        </span>
                        <span>
                          <h6>FedRamp</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="full-image full-length">
                <img src={Image_03} alt="" />
              </div>
            </div>
            <div
              className={
                toggleState === 7 ? "content-block active" : "content-block"
              }
            >
              <div className="title">
                <h2>Affiliated Services </h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 center-align">
                  <div className="content">
                    <p>
                      We are living in the age of connectivity, and that means
                      more things than ever before are right at your fingertips
                      - literally. With one press of the button, one swipe left
                      or right, you can open up new worlds in seconds. We're
                      talking about apps, those little icons on your mobile
                      device. At RedTeamFactory, we don't just talk about apps;
                      we live and breathe apps. We have assembled a team of the
                      best and brightest minds in software development,
                      marketing, and leadership, giving our clients access to
                      the most cutting-edge technology. You can rest assured
                      you're in good hands, as we have years of experience in
                      Web, Android and iOS app development. Our goal is to
                      create something you're proud of and that helps your
                      business. Apps can be transcendent and they can also be
                      colossal failures. That's the reason RedTeamFactory has
                      developed a comprehensive approach to app development that
                      takes the guessing out of the game.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="sub-title">
                    <h5>Core Activities</h5>
                  </div>
                  <div className="icon-section">
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_01} alt="" />
                        </span>
                        <span>
                          <h6>Cloud Infra Management</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_02} alt="" />
                        </span>
                        <span>
                          <h6>Source Code Management</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_03} alt="" />
                        </span>
                        <span>
                          <h6>Solutions Architect</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_04} alt="" />
                        </span>
                        <span>
                          <h6>DevOPS</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_05} alt="" />
                        </span>
                        <span>
                          <h6>Brand Management &amp; Monitoring</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_06} alt="" />
                        </span>
                        <span>
                          <h6>Digital Marketing Services</h6>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_07} alt="" />
                        </span>
                        <span>
                          <h6>Website - UI/UX Development</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_08} alt="" />
                        </span>
                        <span>
                          <h6>Web App &amp; Mobile App Development</h6>
                        </span>
                      </div>
                      <div className="inner-box">
                        <span>
                          <img src={Icon_07_09} alt="" />
                        </span>
                        <span>
                          <h6>Resource Consulting</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
