import React from "react";
import { HashLink } from "react-router-hash-link";

// StyleSheet
import "./Error.scss";

export default function Error() {
  return (
    <>
      <div className="Error">
        <h1>ERROR 4😑4</h1>
        <h2>Page Not Found...!</h2>
        <button>
          <HashLink to="/#">Go To Home Page</HashLink>
        </button>
      </div>
    </>
  );
}
