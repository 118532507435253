import React from "react";

// Image
import Icon01 from "../../../img/penetration-testing/Actionable-Reports-icon.png";
import Icon02 from "../../../img/penetration-testing/No False-Positives-icon.png";
import Icon03 from "../../../img/penetration-testing/Comprehensive-Proces-icon.png";

export default function SectionFour() {
  return (
    <>
      <div className="pen-section-four-block">
        <div className="container">
          <div className="title">
            <h2>Our Deliverables</h2>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="box">
                <img src={Icon01} alt="" />
                <h4>
                  Actionable <br />
                  Reports
                </h4>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <img src={Icon02} alt="" />
                <h4>
                  No False <br />
                  Positives
                </h4>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <img src={Icon03} alt="" />
                <h4>
                  Comprehensive <br />
                  Remediation
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
