import React from "react";

// Image
import Slider01 from "../../../img/managed-security/Slider-06.png";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


export default function ManagedSlider() {
  return (
    <>
      <div className="managed-slider-section">
        <Swiper className="managed-slider">
          <SwiperSlide>
            <img src={Slider01} alt="" />
            <div
              className="slider-text"
              data-aos="fade-down"
              data-aos-mirror="true"
            >
              <h1>
                We help organizations to adopt right technology solutions <br /> 
                to balance benefits against security threats
              </h1>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
