import React from "react";

// StyleSheet
import "./CompanySec.scss";

// Image
import Slider01 from "../../../img/company/Slider-05.png";

// Swiper

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Slider() {
  return (
    <>
      <div className="company-slider-section">
        <Swiper className="company-slider">
          <SwiperSlide>
            <img src={Slider01} alt="" />
            <div
              className="slider-text"
              data-aos="fade-down"
              data-aos-mirror="true"
            >
              <h1>
                Since 2009, we have been implementing projects for modernization
                of <br />
                Information Security as well as creating our own Products
              </h1>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
