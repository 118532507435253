import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

// StyleSheet
import "./PenetrationSec.scss";

// Swiper
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Image src

import Icon01_01 from "../../../img/penetration-testing/Financial-Data-icon.png";
import Icon01_02 from "../../../img/penetration-testing/Trade-Secrets-icon.png";
import Icon01_03 from "../../../img/penetration-testing/User&Customer-Data-icon.png";
import Icon01_04 from "../../../img/penetration-testing/Business-Reputation-icon.png";
import Icon01_05 from "../../../img/penetration-testing/Intellectual-Property-icon.png";
import Icon01_06 from "../../../img/penetration-testing/Technology-Assets-icon.png";
import Icon01_07 from "../../../img/penetration-testing/Security-Strategy-icon.png";
import Icon01_08 from "../../../img/penetration-testing/Secutity-Risks-icon.png";
import Icon01_09 from "../../../img/penetration-testing/Vulnerablityes-icon.png";
import Icon01_10 from "../../../img/penetration-testing/Risk-in-tird-party-tools-icon.png";
import Icon01_11 from "../../../img/penetration-testing/Gaps-between-Security-tools-icon.png";
import Icon01_12 from "../../../img/penetration-testing/Tolerance-of-business-in-cyber-attacks-icon.png";

import Icon02_01 from "../../../img/penetration-testing/Insecure-Configuration-parametes-icon.png";
import Icon02_02 from "../../../img/penetration-testing/Ineffective-firerwall-rules-icon.png";
import Icon02_03 from "../../../img/penetration-testing/Unpatched-systems-icon.png";
import Icon02_04 from "../../../img/penetration-testing/Software-flaws-icon.png";
import Icon02_05 from "../../../img/penetration-testing/Weak-encryption-protocols-icon.png";
import Icon02_06 from "../../../img/penetration-testing/Inadequate-security-controls-icon.png";

import Icon03_01 from "../../../img/penetration-testing/User-Identity-Federation-icon.png";
import Icon03_02 from "../../../img/penetration-testing/Legal&Regulatory-Compliance-icon.png";
import Icon03_03 from "../../../img/penetration-testing/User-Privacy&-Secondary-usage-of-Data-icon.png";
import Icon03_04 from "../../../img/penetration-testing/Service&Data-Integration-icon.png";
import Icon03_05 from "../../../img/penetration-testing/Multi-tenancy&Physical-Security-icon.png";
import Icon03_06 from "../../../img/penetration-testing/Non-production-Environment-Exposure-icon.png";

import Icon04_01 from "../../../img/penetration-testing/Insecure-transport-layer-icon.png";
import Icon04_02 from "../../../img/penetration-testing/Poor-authorization&authentication-icon.png";
import Icon04_03 from "../../../img/penetration-testing/broken-cryptography-icon.png";
import Icon04_04 from "../../../img/penetration-testing/Hardware-Vulnerabilityes-icon.png";
import Icon04_05 from "../../../img/penetration-testing/Distributed-Systems-complexity-icon.png";
import Icon04_06 from "../../../img/penetration-testing/Targeted-Attacks-icon.png";

import Icon05_01 from "../../../img/penetration-testing/Rogue-access-points-icon.png";
import Icon05_02 from "../../../img/penetration-testing/Weak-encryption-icon.png";
import Icon05_03 from "../../../img/penetration-testing/Default-router-setups-icon.png";
import Icon05_04 from "../../../img/penetration-testing/Brute-force-weaknesses-icon.png";
import Icon05_05 from "../../../img/penetration-testing/Bluetooth-exploits-icon.png";
import Icon05_06 from "../../../img/penetration-testing/WPA key-vulnerabilities-icon.png";

import Icon06_01 from "../../../img/penetration-testing/Injection-flaws-icon.png";
import Icon06_02 from "../../../img/penetration-testing/Authentication-weaknesses-icon.png";
import Icon06_03 from "../../../img/penetration-testing/Poor session-management-icon.png";
import Icon06_04 from "../../../img/penetration-testing/Broken-access-controls-icon.png";
import Icon06_05 from "../../../img/penetration-testing/Security-misconfigurations-icon.png";
import Icon06_06 from "../../../img/penetration-testing/Database-interaction-errors-icon.png";

import Icon07_01 from "../../../img/penetration-testing/Weak-server-side-controls-icon.png";
import Icon07_02 from "../../../img/penetration-testing/Insecure-data-storage-icon.png";
import Icon07_03 from "../../../img/penetration-testing/Client-side-injection-icon.png";
import Icon07_04 from "../../../img/penetration-testing/Improper-session-handling-icon.png";
import Icon07_05 from "../../../img/penetration-testing/API-vulnerabilities-icon.png";
import Icon07_06 from "../../../img/penetration-testing/Security-decisions-via-untrusted-inputs-icon.png";

import Icon08_01 from "../../../img/penetration-testing/Insecure.png";
import Icon08_02 from "../../../img/penetration-testing/Botnets.png";
import Icon08_03 from "../../../img/penetration-testing/Tampering.png";
import Icon08_04 from "../../../img/penetration-testing/Ecosystem.png";
import Icon08_05 from "../../../img/penetration-testing/Ransomware.png";
import Icon08_06 from "../../../img/penetration-testing/Legacy.png";

export default function SectionOne({ toggleState, setToggleState }) {
  const [stickyNav, setStickyNav] = useState(!true);

  const subNavBackground = () => {
    if (window.scrollY >= 575) {
      setStickyNav(true);
    } else {
      setStickyNav(false);
    }
  };
  window.addEventListener("scroll", subNavBackground);

  const swapTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
      <div className="penetration-section-one">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 tab-nav">
              <ul className={stickyNav ? "active" : ""}>
                <li
                  className={toggleState === 1 ? "active" : ""}
                  onClick={() => swapTab(1)}
                >
                  <HashLink to="#Scroll-action">
                    Network Infra
                  </HashLink>
                </li>
                <li
                  className={toggleState === 2 ? "active" : ""}
                  onClick={() => swapTab(2)}
                >
                  <HashLink to="#Scroll-action">
                    Cloud Infra
                  </HashLink>
                </li>
                <li
                  className={toggleState === 3 ? "active" : ""}
                  onClick={() => swapTab(3)}
                >
                  <HashLink to="#Scroll-action">
                    Critical Infra
                  </HashLink>
                </li>
                <li
                  className={toggleState === 4 ? "active" : ""}
                  onClick={() => swapTab(4)}
                >
                  <HashLink to="#Scroll-action">
                    Wireless Network
                  </HashLink>
                </li>
                <li
                  className={toggleState === 5 ? "active" : ""}
                  onClick={() => swapTab(5)}
                >
                  <HashLink to="#Scroll-action">
                    Web App's &amp; API’s
                  </HashLink>
                </li>
                <li
                  className={toggleState === 6 ? "active" : ""}
                  onClick={() => swapTab(6)}
                >
                  <HashLink to="#Scroll-action">
                    Mobile App's
                  </HashLink>
                </li>
                <li
                  className={toggleState === 7 ? "active" : ""}
                  onClick={() => swapTab(7)}
                >
                  <HashLink to="#Scroll-action">
                    IOT/IIOT
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="welcome-block">
                  <div className="title">
                    <h2>Penetration Testing</h2>
                  </div>
                  <div className="content">
                    <p>
                      We offer Managed Vulnerability Assessment &amp;
                      Penetration Testing (VAPT) services that benefits
                      organizations to set direction through prioritization and
                      decision making by proactively protecting digital assets
                      from the growing threats. Our solutions facilitate
                      organization by providing visibility of security
                      weaknesses and guidance to address them in a timely
                      manner. Our goals are similar to advanced targeted attacks
                      to provide a detailed view into aggregations of security
                      loopholes that lead to a breach. We also support
                      organizations by providing Compliance based security
                      assessments to maintain their standards like GDPR, HIPPA,
                      SOC, ISO 27001, PCI DSS and much more.
                    </p>
                  </div>
                </div>

                <div className="section-one-inn-block-two">
                  <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12 center-align">
                      <div className="sub-title">
                        <h5>Necessity of Managed VAPT Services</h5>
                      </div>
                      <div className="content">
                        <p>
                          The necessity of VAPT is usually disregarded by
                          organizations; on the contrary every organization is
                          potential target of cyber criminals. Best practice is
                          to conduct VAPT’s periodically as per the requirements
                          or after making any substantial changes to your assets
                          as it is important to analyze technology assets
                          especially connected to internet or on a critical
                          infra, to avoid usual damages or breaches. VAPT
                          periodic practices helps to protect applications/Infra
                          and data corresponding to it which can be attacked by
                          dark technologies used by black hat hackers. VAPT
                          assessments ensures that the data in an organization’s
                          possession is accurate, reliable and secured against
                          unauthorized changes, tampering, destruction or loss.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <Swiper
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                          pauseOnMouseEnter: true,
                        }}
                        speed={1000}
                        pagination={{ clickable: true }}
                        modules={[Pagination, Autoplay]}
                        className="icons-slider"
                      >
                        <SwiperSlide>
                          <div className="title">
                            <h2>We Identify </h2>
                          </div>
                          <div className="icons-box">
                            <div className="box">
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_07} alt="" />
                                </span>
                                <p>
                                  Security
                                  <br />
                                  Strategy
                                </p>
                              </div>
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_10} alt="" />
                                </span>
                                <p>
                                  Risk in Third
                                  <br />
                                  Party Tools
                                </p>
                              </div>
                            </div>
                            <div className="box">
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_08} alt="" />
                                </span>
                                <p>
                                  Security
                                  <br />
                                  Risks
                                </p>
                              </div>
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_11} alt="" />
                                </span>
                                <p>
                                  Gaps in
                                  <br />
                                  Security Tools
                                </p>
                              </div>
                            </div>
                            <div className="box">
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_09} alt="" />
                                </span>
                                <p>Vulnerabilities</p>
                              </div>
                              <div className="inn-box" id="Scroll-action">
                                <span>
                                  <img src={Icon01_12} alt="" />
                                </span>
                                <p>
                                  Tolerance of <br />
                                  Cyber-Attacks
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="title">
                            <h2>We Protect </h2>
                          </div>
                          <div className="icons-box">
                            <div className="box">
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_01} alt="" />
                                </span>
                                <p>
                                  Financial
                                  <br />
                                  Data
                                </p>
                              </div>

                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_04} alt="" />
                                </span>
                                <p>
                                  Business
                                  <br />
                                  Reputation
                                </p>
                              </div>
                            </div>
                            <div className="box">
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_02} alt="" />
                                </span>
                                <p>
                                  Trade
                                  <br />
                                  Secrets
                                </p>
                              </div>
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_05} alt="" />
                                </span>
                                <p>
                                  Intellectual
                                  <br />
                                  Property
                                </p>
                              </div>
                            </div>
                            <div className="box">
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_03} alt="" />
                                </span>
                                <p>
                                  Customer
                                  <br />
                                  Data
                                </p>
                              </div>
                              <div className="inn-box">
                                <span>
                                  <img src={Icon01_06} alt="" />
                                </span>
                                <p>
                                  Technology
                                  <br />
                                  Assets
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    toggleState === 1 ? "tab-content active" : "tab-content"
                  }
                  id="network-sec"
                >
                  <div className="title">
                    <h2>Network Infra </h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12">
                      <p>
                        We evaluate your network infrastructure to identify and
                        exploit a wide range of vulnerabilities. This enables us
                        to identify if any user data, sensitive information,
                        applications used can be compromised and classify the
                        risks posed. We never stop with some common threats and
                        vulnerabilities, we always focus on ensuring E2E
                        security solutions.
                      </p>
                    </div>

                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon02_01} alt="" />
                            </span>
                            <p>
                              Insecure <br />
                              Configuration <br />
                              Parameter
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon02_04} alt="" />
                            </span>
                            <p>
                              Software
                              <br />
                              Flaws
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon02_02} alt="" />
                            </span>
                            <p>
                              Ineffective
                              <br />
                              Firewall Rules
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon02_05} alt="" />
                            </span>
                            <p>
                              Weak
                              <br />
                              Encryption <br />
                              Protocols
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon02_03} alt="" />
                            </span>
                            <p>
                              Unpatched <br />
                              Systems
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon02_06} alt="" />
                            </span>
                            <p>
                              Inadequate <br />
                              Security <br />
                              Controls
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 2 ? "tab-content active" : "tab-content"
                  }
                  id="cloud-sec"
                >
                  <div className="title">
                    <h2>Cloud Infra </h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12" id="Scroll-action-01">
                      <p>
                        We evaluate Cloud Infrastructure by simulating a
                        realistic attack where our assessments are designed
                        specific to the type of cloud model (Public, Private,
                        and Hybrid). This enables us to identify unauthorized
                        virtual and or physical access to sensitive information
                        leading to data breaches and full system & network
                        compromise. We never stop with some common threats and
                        vulnerabilities, we always focus on ensuring E2E
                        security solutions.
                      </p>
                    </div>
                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box ">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon03_01} alt="" />
                            </span>
                            <p>
                              User <br />
                              Identity <br />
                              Federation
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon03_04} alt="" />
                            </span>
                            <p>
                              Service &amp; <br />
                              Data Integration
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon03_02} alt="" />
                            </span>
                            <p>
                              Legal &amp; <br />
                              Regulatory <br />
                              Compliance
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon03_05} alt="" />
                            </span>
                            <p>
                              Multi-tenancy <br />
                              &amp; Physical <br />
                              Security
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon03_03} alt="" />
                            </span>
                            <p>
                              User Privacy &amp; <br />
                              Secondary Usage <br />
                              of Data
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon03_06} alt="" />
                            </span>
                            <p>
                              Non-Production <br />
                              Environment <br />
                              Exposure
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 3 ? "tab-content active" : "tab-content"
                  }
                  id="critical-sec"
                >
                  <div className="title">
                    <h2>Critical Infra </h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12">
                      <p>
                        Critical Infrastructures are setups like data centers or
                        complex infra of Financial, Healthcare, Electricity,
                        Telco, Oil and Gas industries. We never stop with some
                        common threats and vulnerabilities, we always focus on
                        ensuring E2E security solutions. We implement a holistic
                        approach to identify the attack scenarios and breach
                        points to secure the complex and distributed systems.
                      </p>
                    </div>
                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box ">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon04_01} alt="" />
                            </span>
                            <p>
                              Insecure <br />
                              Transport <br />
                              Layer
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon04_04} alt="" />
                            </span>
                            <p>
                              Hardware <br />
                              Vulnerabilities
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon04_02} alt="" />
                            </span>
                            <p>
                              Poor <br />
                              Authorization <br />
                              &amp; Authentication
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon04_05} alt="" />
                            </span>
                            <p>
                              Distributed <br />
                              Systems <br />
                              Complexity
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon04_03} alt="" />
                            </span>
                            <p>
                              Broken <br /> Cryptography
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon04_06} alt="" />
                            </span>
                            <p>
                              Targeted <br /> Attacks
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 4 ? "tab-content active" : "tab-content"
                  }
                  id="wireless-sec"
                >
                  <div className="title ">
                    <h2>Wireless Network </h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12">
                      <p>
                        Wireless networks are used to connect several devices
                        including IOT/IIOT. These networks can enable attackers
                        to enter your system and steal valuable data. We assess
                        to identify vulnerabilities and determine the damage
                        that could cause and remediate it. We never stop with
                        some common threats and vulnerabilities, we always focus
                        on ensuring E2E security solutions to all kind of
                        Wireless Network components.
                      </p>
                    </div>
                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon05_01} alt="" />
                            </span>
                            <p>
                              Rogue Access <br /> Points
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon05_04} alt="" />
                            </span>
                            <p>
                              Brute Force <br /> Weaknesses
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon05_02} alt="" />
                            </span>
                            <p>
                              Weak <br /> Encryption
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon05_05} alt="" />
                            </span>
                            <p>
                              Bluetooth <br /> Exploits
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon05_03} alt="" />
                            </span>
                            <p>
                              Default Router <br /> Setups
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon05_06} alt="" />
                            </span>
                            <p>
                              WPA Key <br /> Vulnerabilities
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 5 ? "tab-content active" : "tab-content"
                  }
                  id="webapp-sec"
                >
                  <div className="title ">
                    <h2>Web App's &amp; API’s</h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12">
                      <p>
                        Several types of Web Applications & API’s are used by
                        organizations and this becomes an obvious target for
                        cyber criminals. Our assessments are carried out as per
                        OWASP top 10 and also our in-house techniques. We
                        implement both automated and manual techniques to
                        achieve better results, not just focusing on common
                        threats and vulnerabilities. We focus on ensuring E2E
                        security solutions towards the component.
                      </p>
                    </div>
                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon06_01} alt="" />
                            </span>
                            <p>
                              Injection <br /> Flaws
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon06_06} alt="" />
                            </span>
                            <p>
                              Broken Access <br /> Controls
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon06_02} alt="" />
                            </span>
                            <p>
                              Authentication <br /> Weaknesses
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon06_05} alt="" />
                            </span>
                            <p>
                              Security <br /> Misconfigurations
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon06_03} alt="" />
                            </span>
                            <p>
                              Poor Session <br /> Management
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon06_04} alt="" />
                            </span>
                            <p>
                              Database <br /> Interaction <br /> Errors
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 6 ? "tab-content active" : "tab-content"
                  }
                  id="mobileapp-sec"
                >
                  <div className="title">
                    <h2>Mobile App's </h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12">
                      <p>
                        Mobile App usage is on the rise to carry out different
                        activities, when compromised it’s not just the app data,
                        also the personal data stored in the mobile devices. Our
                        assessments are carried out in-depth, techniques with
                        latest development frameworks and security testing
                        tools. We follow OWASP mobile top 10 and our in-house
                        strategy to identify different vulnerabilities of IOS &
                        Android apps. We never stop with some common threats and
                        vulnerabilities, we always focus on ensuring E2E
                        security solutions.
                      </p>
                    </div>
                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon07_01} alt="" />
                            </span>
                            <p>
                              Weak <br /> Server-Side <br /> Controls
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon07_04} alt="" />
                            </span>
                            <p>
                              Improper <br /> Session <br /> Handling
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon07_02} alt="" />
                            </span>
                            <p>
                              Insecure Data <br /> Storage
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon07_05} alt="" />
                            </span>
                            <p>
                              API <br /> Vulnerabilities
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon07_03} alt="" />
                            </span>
                            <p>
                              Client-Side <br /> Injection
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon07_06} alt="" />
                            </span>
                            <p>
                              Security Decisions <br /> via Untrusted <br />
                              Inputs
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 7 ? "tab-content active" : "tab-content"
                  }
                  id="iot-sec"
                >
                  <div className="title">
                    <h2>IOT/IIOT </h2>
                  </div>
                  <div className="row">
                    <div className="content col-lg-5 col-md-12 col-sm-12">
                      <p>
                        IOT/IIOT setups are usually distributed system wherein
                        multiple cyber processes communicate their state
                        information to the control devices that take up some
                        control decisions, on a set of physical resources. Any
                        falsification or fabrication in this system will lead to
                        severe damages. Offensive security practices will be
                        implemented to find new weak chains using different
                        tools, techniques and procedures which will be used to
                        achieve the best level of security in each layer and its
                        relation and provide defensive measures with it.
                      </p>
                    </div>
                    <div className="icons-section col-lg-7 col-md-12 col-sm-12">
                      <div className="sub-title">
                        <h5>Common Threats and Vulnerabilities</h5>
                      </div>
                      <div className="icons-box">
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon08_01} alt="" />
                            </span>
                            <p>
                              Insecure <br /> Data Storage <br /> &amp; Transfer
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon08_02} alt="" />
                            </span>
                            <p>Botnets</p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon08_03} alt="" />
                            </span>
                            <p>
                              Remote <br /> Tampering
                            </p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon08_04} alt="" />
                            </span>
                            <p>
                              Insecure Ecosystem <br /> Interfaces
                            </p>
                          </div>
                        </div>
                        <div className="box">
                          <div className="inn-box">
                            <span>
                              <img src={Icon08_05} alt="" />
                            </span>
                            <p>Ransomware</p>
                          </div>
                          <div className="inn-box">
                            <span>
                              <img src={Icon08_06} alt="" />
                            </span>
                            <p>
                              Legacy <br /> Assets
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
