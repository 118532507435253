import React from "react";

// Video
import BgVideo from "../../../video/banner-01.mp4";

export default function Banner() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="banner-video">
          <video muted loop autoPlay>
            <source src={BgVideo} />
          </video>
          <div className="title">
            <h1>
              Adoption of <span>Red Teaming</span> Provides Critical Business
              Solutions
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
