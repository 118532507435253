import React from "react";
import ReactDOM from "react-dom";

// StyleSheet
import "./index.scss";

// Source File
import App from "./App";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// Swiper Js
import "swiper/css/bundle";

// Slick Js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fonts
import "./fonts/Proxima Nova Alt Bold.otf";
import "./fonts/Proxima Nova Alt Light.otf";
import "./fonts/Proxima Nova Alt Thin.otf";
import "./fonts/Proxima Nova Black.otf";
import "./fonts/Proxima Nova Bold.otf";
import "./fonts/Proxima Nova Extrabold.otf";
import "./fonts/Proxima Nova Thin.otf";
import "./fonts/ProximaNova-Regular.otf";

ReactDOM.render(<App />, document.getElementById("root"));
