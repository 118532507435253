import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

// Icon
import { FiArrowRightCircle } from "react-icons/fi";
import { BiChevronDown } from "react-icons/bi";
// Image src
import Logo from "../../img/layout/logo-02.png";

// Stylesheet
import "./LayoutStyle.scss";

export default function Header({ setToggleState, setToggle }) {
  const [header, setHeader] = useState(!true);

  const navBackground = () => {
    if (window.scrollY >= 100) {
      setHeader(true);
    } else setHeader(false);
  };
  window.addEventListener("scroll", navBackground);

  return (
    <>
      <nav className={header ? "header active" : "header"}>
        <div className="container-fluid header-bar">
          <div className="row center-align">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-2 col-xl-3 col-width">
              <div className="logo">
                <HashLink to="/#">
                  <img src={Logo} alt="logo" />
                </HashLink>
              </div>
            </div>
            <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-10 col-xl-9 col-width">
              <div className="pc-nav-menu">
                <PcMenu setToggleState={setToggleState} setToggle={setToggle} />
              </div>
              <div className="tab-nav-menu">
                <TabMenu
                  setToggleState={setToggleState}
                  setToggle={setToggle}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

function PcMenu({ setToggleState, setToggle }) {
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const swapTab = (index) => {
    setToggle(index);
    setToggleState(index);
  };

  const ls = 1100;
  const ms = 770;
  const ss = 500;
  const xs = 499;

  var screenWidth = document.body.scrollWidth;
  // console.log(screenWidth);

  const penetrationScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1700);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2200);
    }
  };

  const managedScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1300);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1500);
    } else if (screenWidth <= xs) {
      window.scroll(0, 1600);
    }
  };
  const assessmentScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 2200);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1900);
    } else if (screenWidth >= ss) {
      window.scroll(0, 2400);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2500);
    }
  };

  return (
    <>
      <ul className="menu">
        <li className="main-menu">
          <HashLink to="/company#" className="main-nav-link">
            Company
          </HashLink>
          <ul className="sub-menu">
            <li>
              <HashLink to="/company" className="nav-link">
                About
              </HashLink>
            </li>
            <li>
              <HashLink to="/company#awards" className="nav-link">
                Awards &amp; Recognition
              </HashLink>
            </li>
            <li>
              <HashLink to="/company#clients" className="nav-link">
                Clients &amp; Partners
              </HashLink>
            </li>
            <li>
              <HashLink to="/company#partners" className="nav-link">
                Community Partners
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="main-menu">
          <HashLink
            to="/offensive-security#"
            className="main-nav-link"
            onClick={() => swapTab(0)}
          >
            Offensive Security
          </HashLink>
          <ul className="sub-menu">
            <li>
              <HashLink
                to="/offensive-security#Services"
                className="nav-link"
                onClick={() => swapTab(1)}
              >
                Cyber Red Team
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/offensive-security#Services"
                className="nav-link"
                onClick={() => swapTab(2)}
              >
                Cyber Purple Team
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/offensive-security#Services"
                className="nav-link"
                onClick={() => swapTab(3)}
              >
                R &amp; D
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="main-menu">
          <HashLink
            to="/penetration-testing#"
            className="main-nav-link"
            onClick={() => swapTab(1)}
          >
            Penetration Testing
          </HashLink>
          <ul className="sub-menu">
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(1);
                  penetrationScrollTo();
                }}
              >
                Network Infrastructure
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(2);
                  penetrationScrollTo();
                }}
              >
                Cloud Infrastructure
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(3);
                  penetrationScrollTo();
                }}
              >
                Critical Infrastructure
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(4);
                  penetrationScrollTo();
                }}
              >
                Wireless Network
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(5);
                  penetrationScrollTo();
                }}
              >
                Web Application
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(6);
                  penetrationScrollTo();
                }}
              >
                Mobile Application
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/penetration-testing"
                className="nav-link"
                onClick={() => {
                  swapTab(7);
                  penetrationScrollTo();
                }}
              >
                IOT/IIOT
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="main-menu">
          <HashLink
            to="/managed-security#"
            className="main-nav-link"
            onClick={() => swapTab(1)}
          >
            Managed Security
          </HashLink>
          <ul className="sub-menu">
            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(1);
                  managedScrollTo();
                }}
              >
                Cloud Security
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(2);
                  managedScrollTo();
                }}
              >
                SOC as Service
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(3);
                  managedScrollTo();
                }}
              >
                Blue Teaming
              </HashLink>
            </li>

            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(4);
                  managedScrollTo();
                }}
              >
                Intel Solution
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(5);
                  managedScrollTo();
                }}
              >
                Dev Sec Ops
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(6);
                  managedScrollTo();
                }}
              >
                Compliance Implementation
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/managed-security"
                className="nav-link"
                onClick={() => {
                  swapTab(7);
                  managedScrollTo();
                }}
              >
                Affiliated Services
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="main-menu">
          <HashLink
            to="/security-assessment#"
            className="main-nav-link"
            onClick={() => swapTab(1)}
          >
            Security Assessment
          </HashLink>
          <ul className="sub-menu">
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(1);
                  assessmentScrollTo();
                }}
              >
                Risk Assessment
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(2);
                  assessmentScrollTo();
                }}
              >
                Compromise Assessment
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(3);
                  assessmentScrollTo();
                }}
              >
                Security Configuration Assessment
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(4);
                  assessmentScrollTo();
                }}
              >
                Vulnerability Assessment
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(5);
                  assessmentScrollTo();
                }}
              >
                Social Engineering Assessment
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(6);
                  assessmentScrollTo();
                }}
              >
                Compliance based Assessment
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/security-assessment"
                className="nav-link"
                onClick={() => {
                  toggleTab(7);
                  assessmentScrollTo();
                }}
              >
                OSINT
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="main-menu">
          <HashLink to="/innovations/#" className="main-nav-link">
            Innovations
          </HashLink>
          <ul className="sub-menu">
            <li>
              <HashLink to="/innovations#" className="nav-link">
                BugDiscover
              </HashLink>
            </li>
            <li>
              <HashLink to="/innovations/#inno-sec-five" className="nav-link">
                CODINT (coming soon)
              </HashLink>
            </li>
            <li>
              <HashLink to="/innovations/#inno-sec-five" className="nav-link">
                Breach Panda (coming soon)
              </HashLink>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
}

function TabMenu({ setToggleState, setToggle }) {
  const [toggleMenu, setToggleMenu] = useState(!true);
  const toggleTab = (index) => {
    setToggleState(index);
    setToggleMenu(!toggleMenu);
    setSubMenuToggle(!true);
  };
  const swapTab = (index) => {
    setToggle(index);
    setToggleState(index);
    setToggleMenu(!toggleMenu);
    setSubMenuToggle(!true);
  };

  const showToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const [subMenuToggle, setSubMenuToggle] = useState(!true);

  const subMenuDropDown = (index) => {
    setSubMenuToggle(index);
  };

  const ls = 1100;
  const ms = 770;
  const ss = 500;
  const xs = 499;

  var screenWidth = document.body.scrollWidth;
  // console.log(screenWidth);

  const offensiveScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1700);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2200);
    }
  };

  const penetrationScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1700);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2200);
    }
  };

  const managedScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 1500);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1300);
    } else if (screenWidth >= ss) {
      window.scroll(0, 1500);
    } else if (screenWidth <= xs) {
      window.scroll(0, 1600);
    }
  };
  const assessmentScrollTo = () => {
    if (screenWidth >= ls) {
      window.scroll(0, 2200);
    } else if (screenWidth >= ms) {
      window.scroll(0, 1900);
    } else if (screenWidth >= ss) {
      window.scroll(0, 2400);
    } else if (screenWidth <= xs) {
      window.scroll(0, 2500);
    }
  };

  return (
    <>
      <div
        className={toggleMenu ? "menu-open-button active" : "menu-open-button"}
        onClick={showToggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={toggleMenu ? "toggle-menu active" : "toggle-menu"}>
        <div
          className={
            toggleMenu ? "menu-close-button " : "menu-close-button active"
          }
          onClick={showToggleMenu}
        >
          <span>
            <FiArrowRightCircle />
          </span>
        </div>
        <nav>
          <ul className="menu-list">
            <li className="main-menu-title">
              <div className="menu-link">
                <HashLink
                  to="/company#"
                  className="main-nav-link"
                  onClick={toggleTab}
                >
                  Company
                </HashLink>
                <span
                  className={subMenuToggle === 1 ? "active" : ""}
                  onClick={() => {
                    subMenuDropDown(1);
                  }}
                >
                  <BiChevronDown />
                </span>
              </div>

              <ul
                className={subMenuToggle === 1 ? "active sub-menu" : "sub-menu"}
              >
                <li>
                  <HashLink
                    to="/company"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    About
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/company#awards"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    Awards &amp; Recognition
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/company#clients"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    Clients &amp; Partners
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/company#partners"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    Community Partners
                  </HashLink>
                </li>
              </ul>
            </li>
            <li className="main-menu-title">
              <div className="menu-link">
                <HashLink
                  to="/offensive-security#"
                  className="main-nav-link"
                  onClick={toggleTab}
                >
                  Offensive Security
                </HashLink>
                <span
                  className={subMenuToggle === 2 ? "active" : ""}
                  onClick={() => {
                    subMenuDropDown(2);
                  }}
                >
                  <BiChevronDown />
                </span>
              </div>

              <ul
                className={subMenuToggle === 2 ? "active sub-menu" : "sub-menu"}
              >
                <li>
                  <HashLink
                    to="/offensive-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(1);
                      offensiveScrollTo();
                    }}
                  >
                    Cyber Red Team
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/offensive-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(2);
                      offensiveScrollTo();
                    }}
                  >
                    Cyber Purple Team
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/offensive-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(3);
                      offensiveScrollTo();
                    }}
                  >
                    R &amp; D
                  </HashLink>
                </li>
              </ul>
            </li>

            <li className="main-menu-title">
              <div className="menu-link">
                <HashLink
                  to="/penetration-testing#"
                  className="main-nav-link"
                  onClick={toggleTab}
                >
                  Penetration Testing
                </HashLink>
                <span
                  className={subMenuToggle === 3 ? "active" : ""}
                  onClick={() => {
                    subMenuDropDown(3);
                  }}
                >
                  <BiChevronDown />
                </span>
              </div>

              <ul
                className={subMenuToggle === 3 ? "active sub-menu" : "sub-menu"}
              >
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(1);
                      penetrationScrollTo();
                    }}
                  >
                    Network Infrastructure
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(2);
                      penetrationScrollTo();
                    }}
                  >
                    Cloud Infrastructure
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(3);
                      penetrationScrollTo();
                    }}
                  >
                    Critical Infrastructure
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(4);
                      penetrationScrollTo();
                    }}
                  >
                    Wireless Network
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(5);
                      penetrationScrollTo();
                    }}
                  >
                    Web Application
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(6);
                      penetrationScrollTo();
                    }}
                  >
                    Mobile Application
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/penetration-testing"
                    className="nav-link"
                    onClick={() => {
                      swapTab(7);
                      penetrationScrollTo();
                    }}
                  >
                    IOT/IIOT
                  </HashLink>
                </li>
              </ul>
            </li>
            <li className="main-menu-title">
              <div className="menu-link">
                <HashLink
                  to="/managed-security#"
                  className="main-nav-link"
                  onClick={toggleTab}
                >
                  Managed Security
                </HashLink>
                <span
                  className={subMenuToggle === 4 ? "active" : ""}
                  onClick={() => {
                    subMenuDropDown(4);
                  }}
                >
                  <BiChevronDown />
                </span>
              </div>

              <ul
                className={subMenuToggle === 4 ? "active sub-menu" : "sub-menu"}
              >
                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(1);
                      managedScrollTo();
                    }}
                  >
                    Cloud Security
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(2);
                      managedScrollTo();
                    }}
                  >
                    SOC as Service
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(3);
                      managedScrollTo();
                    }}
                  >
                    Blue Teaming
                  </HashLink>
                </li>

                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(4);
                      managedScrollTo();
                    }}
                  >
                    Intel Solution
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(5);
                      managedScrollTo();
                    }}
                  >
                    Dev Sec Ops
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(6);
                      managedScrollTo();
                    }}
                  >
                    Compliance Implementation
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/managed-security"
                    className="nav-link"
                    onClick={() => {
                      swapTab(7);
                      managedScrollTo();
                    }}
                  >
                    Affiliated Services
                  </HashLink>
                </li>
              </ul>
            </li>
            <li className="main-menu-title">
              <div className="menu-link">
                <HashLink
                  to="/security-assessment#"
                  className="main-nav-link"
                  onClick={toggleTab}
                >
                  Security Assessment
                </HashLink>
                <span
                  className={subMenuToggle === 5 ? "active" : ""}
                  onClick={() => {
                    subMenuDropDown(5);
                  }}
                >
                  <BiChevronDown />
                </span>
              </div>

              <ul
                className={subMenuToggle === 5 ? "active sub-menu" : "sub-menu"}
              >
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(1);
                      assessmentScrollTo();
                    }}
                  >
                    Risk Assessment
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(2);
                      assessmentScrollTo();
                    }}
                  >
                    Compromise Assessment
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(3);
                      assessmentScrollTo();
                    }}
                  >
                    Security Configuration Assessment
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(4);
                      assessmentScrollTo();
                    }}
                  >
                    Vulnerability Assessment
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(5);
                      assessmentScrollTo();
                    }}
                  >
                    Social Engineering Assessment
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(6);
                      assessmentScrollTo();
                    }}
                  >
                    Compliance based Assessment
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/security-assessment"
                    className="nav-link"
                    onClick={() => {
                      toggleTab(7);
                      assessmentScrollTo();
                    }}
                  >
                    OSINT
                  </HashLink>
                </li>
              </ul>
            </li>
            <li className="main-menu-title">
              <div className="menu-link">
                <HashLink
                  to="/innovations#"
                  className="main-nav-link"
                  onClick={toggleTab}
                >
                  Innovations
                </HashLink>
                <span
                  className={subMenuToggle === 6 ? "active" : ""}
                  onClick={() => {
                    subMenuDropDown(6);
                  }}
                >
                  <BiChevronDown />
                </span>
              </div>

              <ul
                className={subMenuToggle === 6 ? "active sub-menu" : "sub-menu"}
              >
                <li>
                  <HashLink
                    to="/innovations#"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    BugDiscover
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/innovations/#inno-sec-five"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    CODINT (coming soon)
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/innovations/#inno-sec-five"
                    className="nav-link"
                    onClick={toggleTab}
                  >
                    Breach Panda (coming soon)
                  </HashLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
