import React from "react";

// Stylesheet
import "../layouts/OffensiveSec.scss";

// Image
import Img_01 from "../../../img/offensive-security/image-01.png";

export default function SectionOne() {
  return (
    <>
      <div className="offensive-section-one">
        <div className="container">
          <div className="title" data-aos="fade-in">
            <h2>Offensive Security Solutions</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 center-align" data-aos="fade-in"> 
              <div className="content">
                <p>
                  Cyber attacks are being conducted every day on any type of
                  target, and any notion that a state of full cyber security can
                  be reached is a mere illusion. The best way to evaluate
                  against these advanced threats is by operating same tactics,
                  techniques and procedures (TTPs) as the adversaries
                  themselves. Our offensive security solutions are aimed to
                  reveal real world opportunity for malicious insiders or bad
                  actors who could be able to compromise all aspects of your
                  organization, in such a way that allows for unauthorized
                  virtual and or physical access to sensitive information
                  leading up to data breaches and full system or network
                  compromise.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12" data-aos="zoom-in">
              <div className="full-img">
                <img src={Img_01} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
