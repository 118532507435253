import React from "react";

// image src
import Slider01 from "../../../img/security-assessment/Slider-04.png";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function AssessmentSlider() {
  return (
    <>
      <div className="assessment-slider-section">
        <Swiper className="assessment-slider">
          <SwiperSlide>
            <img src={Slider01} alt="" />
            <div
              className="slider-text"
              data-aos="fade-down"
              data-aos-mirror="true"
            >
              <h1>Info Sec is not a Product it’s a process</h1>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
