import React, { useState } from "react";

// Stylesheet
import "./CompanySec.scss";

// image src
import Image_02 from "../../../img/company/community partner/02.png";
import Image_03 from "../../../img/company/community partner/03.png";
import Image_04 from "../../../img/company/community partner/04.png";
import Image_05 from "../../../img/company/community partner/05.png";
import Image_06 from "../../../img/company/community partner/06.png";
import Image_07 from "../../../img/company/community partner/07.png";
import Image_08 from "../../../img/company/community partner/08.png";
import Image_09 from "../../../img/company/community partner/09.png";
import Image_10 from "../../../img/company/community partner/10.png";

// Swiper
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Icon
import { FiPlus } from "react-icons/fi";

export default function Partners() {
  const [toggle, setToggle] = useState(!true);

  const popUp = () => {
    setToggle(true);
  };
  const close = () => {
    setToggle(!true);
  };
  return (
    <>
      <div className="partners-section-block" data-aos="fade-in">
        <div className="container">
          <div className="title">
            <h2>
              Community Partners
              <span onClick={popUp}>
                <FiPlus />
              </span>
            </h2>
            <div className={toggle ? "active pop-up" : "pop-up"}>
              <div className="close-btn" onClick={close}>
                X
              </div>
             
                <img src={Image_02} alt="" />
                <img src={Image_03} alt="" />
                <img src={Image_04} alt="" />
                <img src={Image_05} alt="" />
                <img src={Image_06} alt="" />
                <img src={Image_07} alt="" />
                <img src={Image_08} alt="" />
                <img src={Image_09} alt="" />
                <img src={Image_10} alt="" />
              
            </div>
          </div>
          <div className="col-12">
            <Swiper
              slidesPerView={6}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                500: {
                  slidesPerView: 6,
                },
              }}
              speed={1000}
              modules={[Autoplay]}
              className="client-slider"
            >
              <SwiperSlide>
                <img src={Image_02} alt="" />
                <img src={Image_02} alt="" />
                <img src={Image_02} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_04} alt="" />
                <img src={Image_04} alt="" />
                <img src={Image_04} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_06} alt="" />
                <img src={Image_06} alt="" />
                <img src={Image_06} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_05} alt="" />
                <img src={Image_05} alt="" />
                <img src={Image_05} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_03} alt="" />
                <img src={Image_03} alt="" />
                <img src={Image_03} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_07} alt="" />
                <img src={Image_07} alt="" />
                <img src={Image_07} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_08} alt="" />
                <img src={Image_08} alt="" />
                <img src={Image_08} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_10} alt="" />
                <img src={Image_10} alt="" />
                <img src={Image_10} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Image_09} alt="" />
                <img src={Image_09} alt="" />
                <img src={Image_09} alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
