import React from "react";



// layouts
import Slider from "./layouts/Slider";
import SectionOne from "./layouts/SectionOne";
import SectionTwo from "./layouts/SectionTwo";

// Add-on's
import SecDivider from "../../components/Additional/divider/SecDivider";

export default function Managed({ toggleState, setToggleState }) {
  return (
    <>
      <div className="slider-section" id="#">
        <Slider />
      </div>
      <SecDivider />
      <div className="section-one">
        <SectionOne />
      </div>
      <SecDivider />
      <div className="section-two">
        <SectionTwo toggleState={toggleState} setToggleState={setToggleState} />
      </div>
    </>
  );
}
