import React, { useState, useEffect } from "react";

export default function Loading() {
  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 2000));

      // Toggle loading state
      setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  // If page is in loading state, display
  // loading message. Modify it as per your
  // requirement.
  //   if (loading) {
  //     return <div>Loading....</div>;
  //   }

  //   // If page is not in loading state, display page.
  //   else {
  //     return;
  //   }
}
